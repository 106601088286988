import React, { useEffect, useState } from "react";
import axios from "axios";
import { Tab, Tabs, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

function ViewApplicantsPage() {
  const [applicants, setApplicants] = useState([]);
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  // Fetch applicants from backend
  useEffect(() => {
    const fetchApplicants = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://healthcare.visionkraftconsulting.com/api/applicants"
        );

        if (response.data && response.data.success && response.data.applications) {
          setApplicants(response.data.applications);
          setError("");
        } else {
          throw new Error("Invalid response structure");
        }
      } catch (err) {
        setError("Failed to load applicants. Please try again.");
        console.error("[ERROR] Fetch applicants failed:", err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchApplicants();
  }, []);

  // Render key-value pairs for objects
  const renderKeyValuePairs = (obj) => {
    if (!obj || typeof obj !== "object" || Array.isArray(obj)) {
      return <p className="text-muted">No Data</p>;
    }
    return (
      <ul>
        {Object.entries(obj).map(([key, value]) => (
          <li key={key}>
            <strong>{key}:</strong> {value || "N/A"}
          </li>
        ))}
      </ul>
    );
  };

  // Render list of items for JSON array fields
  const renderList = (array, keys) => {
    if (!array || array.length === 0) return <p className="text-muted">No Data</p>;
    return (
      <ul>
        {array.map((item, index) => (
          <li key={index}>
            {keys.map((key) => (
              <p key={key}>
                <strong>{key}:</strong> {item[key] || "N/A"}
              </p>
            ))}
            <hr />
          </li>
        ))}
      </ul>
    );
  };

  // Render file link with preview for uploaded files
// Render file link with a preview (PDFs or images)
const renderFileLinkWithPreview = (url, label) => {
  if (url) {
    const isImage = /\.(jpg|jpeg|png|gif)$/i.test(url);
    const isPDF = /\.pdf$/i.test(url);

    return (
      <div>
        <a href={url} target="_blank" rel="noopener noreferrer">
          View {label}
        </a>
        {isImage && (
          <div style={{ marginTop: "10px" }}>
            <img
              src={url}
              alt={label}
              style={{
                maxWidth: "200px",
                maxHeight: "200px",
                border: "1px solid #ccc",
                borderRadius: "5px",
              }}
            />
          </div>
        )}
        {isPDF && (
          <div style={{ marginTop: "10px" }}>
            <embed
              src={url}
              type="application/pdf"
              style={{ width: "100%", height: "500px", border: "1px solid #ccc" }}
            />
          </div>
        )}
      </div>
    );
  }
  return "Not Uploaded";
};

  return (
    <div className="container mt-4">
      <h1 className="mb-4">Job Applicants</h1>

      {loading && (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <p>Loading applicants...</p>
        </div>
      )}

      {error && <div className="alert alert-danger">{error}</div>}

      <div className="row">
        {/* Sidebar: List of Applicants */}
        <div className="col-md-4">
          <h4>Applicants</h4>
          {applicants.length > 0 ? (
            <ul className="list-group">
              {applicants.map((applicant) => (
                <li
                  key={applicant.id}
                  className={`list-group-item ${
                    selectedApplicant?.id === applicant.id ? "active" : ""
                  }`}
                  onClick={() => setSelectedApplicant(applicant)}
                  style={{ cursor: "pointer" }}
                >
                  {applicant.name || "Unnamed Applicant"}
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-muted">No applicants available.</p>
          )}
        </div>

        {/* Main Content: Applicant Details */}
        <div className="col-md-8">
          {selectedApplicant ? (
            <Tabs defaultActiveKey="personalInfo" className="mb-3">
              {/* Personal Info Tab */}
              <Tab eventKey="personalInfo" title="Personal Info">
                <h4>Personal Information</h4>
                <p>
                  <strong>Name:</strong> {selectedApplicant.name || "N/A"}
                </p>
                <p>
                  <strong>Email:</strong> {selectedApplicant.email || "N/A"}
                </p>
                <p>
                  <strong>Phone:</strong> {selectedApplicant.phone || "N/A"}
                </p>
                <p>
                  <strong>Address:</strong> {selectedApplicant.address || "N/A"}
                </p>
                <p>
                  <strong>License Number:</strong> {selectedApplicant.licenseNumber || "N/A"}
                </p>
                <p>
                  <strong>License State:</strong> {selectedApplicant.licenseState || "N/A"}
                </p>
                <p>
                  <strong>Resume:</strong>{" "}
                  {renderFileLinkWithPreview(selectedApplicant.files?.resume, "Resume")}
                </p>
              </Tab>

              {/* Application Details Tab */}
              <Tab eventKey="applicationDetails" title="Application Details">
                <h4>Application Details</h4>
                <p>
                  <strong>PDF:</strong>{" "}
                  {renderFileLinkWithPreview(selectedApplicant.files?.pdf, "Application PDF")}
                </p>
                <p>
                  <strong>References:</strong>
                </p>
                {renderList(selectedApplicant.references, ["name", "title", "phone", "datesKnown"])}
                <p>
                  <strong>Experience:</strong>
                </p>
                {renderList(selectedApplicant.experience, ["organization", "contact", "phone", "dates", "contactAllowed"])}
              </Tab>

              {/* Availability Tab */}
              <Tab eventKey="availability" title="Availability">
                <h4>Availability</h4>
                <p>
                  <strong>Overall Availability:</strong>{" "}
                  {selectedApplicant.availability?.allHours
                    ? "Available All Hours"
                    : "Specific Schedule"}
                </p>
                <h5>Schedule:</h5>
                {renderKeyValuePairs(selectedApplicant.availability?.schedule)}
                <p>
                  <strong>Live-in Availability:</strong>{" "}
                  {selectedApplicant.availability?.liveIn ? "Yes" : "No"}
                </p>
                <p>
                  <strong>Max Live-in Days:</strong>{" "}
                  {selectedApplicant.availability?.maxLiveInDays || "N/A"}
                </p>
              </Tab>

              {/* Education Tab */}
              <Tab eventKey="education" title="Education">
                <h4>Education</h4>
                {renderList(selectedApplicant.education, [
                  "name",
                  "location",
                  "major",
                  "graduate",
                  "endDate",
                  "graduated",
                ])}
              </Tab>

              {/* Additional Info Tab */}
              <Tab eventKey="additionalInfo" title="Additional Info">
                <h4>Additional Information</h4>
                <p>
                  <strong>Emergency Contacts:</strong>
                </p>
                {renderList(selectedApplicant.emergencyContacts, [
                  "name",
                  "relationship",
                  "phone",
                  "altPhone",
                ])}
                <p>
                  <strong>Criminal History:</strong>
                </p>
                {renderKeyValuePairs(selectedApplicant.criminalHistory)}
                <p>
                  <strong>Smoker Info:</strong>
                </p>
                {renderKeyValuePairs(selectedApplicant.smokerInfo)}
              </Tab>

              {/* Uploaded Documents Tab */}
              <Tab eventKey="files" title="Uploaded Documents">
              <h4>Uploaded Documents</h4>
              <p>
                <strong>Resume:</strong>{" "}
                {renderFileLinkWithPreview(selectedApplicant.files?.resume, "Resume")}
              </p>
              <p>
                <strong>Application PDF:</strong>{" "}
                {renderFileLinkWithPreview(selectedApplicant.files?.pdf, "Application PDF")}
              </p>
              <p>
                <strong>CPR Certificate:</strong>{" "}
                {renderFileLinkWithPreview(selectedApplicant.files?.cpr, "CPR Certificate")}
              </p>
              <p>
                <strong>TB Test:</strong>{" "}
                {renderFileLinkWithPreview(selectedApplicant.files?.tb, "TB Test")}
              </p>
              <p>
                <strong>License:</strong>{" "}
                {renderFileLinkWithPreview(selectedApplicant.files?.license, "License")}
              </p>
              <p>
                <strong>SSN:</strong>{" "}
                {renderFileLinkWithPreview(selectedApplicant.files?.ssn, "SSN")}
              </p>
              <p>
                <strong>Driver’s License:</strong>{" "}
                {renderFileLinkWithPreview(selectedApplicant.files?.dl, "Driver’s License")}
              </p>
            </Tab>
            </Tabs>
          ) : (
            <p className="text-muted">Select an applicant to view details.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewApplicantsPage;