import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import SignatureCanvas from 'react-signature-canvas';


function JobApplicationPage() {
    const signatureRef = useRef(null); // Ref for the signature canvas
    const [formData, setFormData] = useState({
        // Personal Information
        name: '',
        email: '',
        phone: '',
        address: '',
        licenseNumber: '',
        licenseState: '',
        ssn: '', // Added SSN to match fields
        birthdate: '', // Added birthdate for clarity
      
        // Experience Section
        experience: [
          {
            organization: '',
            contact: '',
            phone: '',
            dates: '',
            contactAllowed: false,
          },
        ],
      
        // References Section
        references: [
          {
            name: '',
            title: '',
            phone: '',
            datesKnown: '',
          },
        ],
      
        // Education Section
        education: [
          {
            name: '',
            location: '',
            major: '',
            graduate: false,
            endDate: '',
          },
        ],
      
        // Availability Section
        availability: {
          allHours: false,
          schedule: {
            Mon: '',
            Tues: '',
            Wed: '',
            Thurs: '',
            Fri: '',
            Sat: '',
            Sun: '',
          },
          liveIn: false,
          maxLiveInDays: '',
        },
      
        // Skills and Preferences
        skills: [], // Initialize the skills field as an empty array
        willingToWorkWith: [], // Default to empty array
        experienceWith: [], // Default to empty array
        certifications: '', // Default to empty string
        transportation: null, // Boolean for reliable transportation
        smoker: { isSmoker: false, perDay: '' }, // Nested object for smoker details
        hearAboutUs: '', // Default to empty string
        caregivingExperience: '', // Default to empty string
        motivation: '', // Default to empty string
      
        // Emergency Contacts
        emergencyContacts: [
          {
            name: '',
            relationship: '',
            phone: '',
            altPhone: '',
          },
        ],
      
        // Criminal History
        criminalHistory: {
          hasRecord: false,
          description: '',
        },
      
        // Documents and Files
        resume: null, // File for resume upload
        additionalDocuments: {
          cpr: null, // CPR certificate file
          tb: null, // TB Test Results file
          license: null, // Professional License file
          ssn: null, // SSN file
          dl: null, // Driver’s License file
        },
      
        // Smoking Information
        smoker: {
          isSmoker: false,
          perDay: '', // Count of cigarettes per day if smoker
        },
      
        // Additional Questions
        additionalQuestions: {
          transportation: false, // Access to transportation
          experience: '', // Additional experience explanation
          reason: '', // Reason for applying
        },
      
        // Certifications and Motivations
        certifications: '', // Text field for certifications
        transportation: false, // Boolean for transportation access
        hearAboutUs: '', // Source of application
        caregivingExperience: '', // Explanation of caregiving experience
        motivation: '', // Motivation for applying
      
        // Agreements
        certificationAgree: null, // Agreement to certification
        restrictiveCovenantAgree: null, // Agreement to restrictive covenant
      
        // Signature
        applicantSignature: '', // Base64-encoded signature data
      });


        // Handle input change and save to state and localStorage

  const [currentTab, setCurrentTab] = useState(0);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const savedFormData = localStorage.getItem('formData');
    if (savedFormData) {
      setFormData(JSON.parse(savedFormData));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('formData', JSON.stringify(formData));
  }, [formData]);

  const handleCheckboxChange = (key, value) => {
    if (!key || !value) {
      console.error(`Invalid key or value provided. Key: ${key}, Value: ${value}`);
      return;
    }
  
    setFormData((prev) => {
      // Ensure the key exists in formData and is an array
      if (!Array.isArray(prev[key])) {
        console.error(`Key "${key}" is not an array in formData. Please check the initial state.`);
        return prev; // Prevent state mutation if the key isn't an array
      }
  
      const updatedArray = prev[key].includes(value)
        ? prev[key].filter((item) => item !== value) // Remove the value if already selected
        : [...prev[key], value]; // Add the value if not selected
  
      // Log updated state for debugging
      console.log(`Updated "${key}":`, updatedArray);
  
      return { ...prev, [key]: updatedArray };
    });
  };

  const handleSpecificFileChange = (e, key) => {
    const file = e.target.files[0];
    if (file) {
      const filePath = URL.createObjectURL(file); // Generate a temporary URL
      setFormData((prev) => ({
        ...prev,
        additionalDocuments: {
          ...prev.additionalDocuments,
          [key]: file,
          [`${key}Path`]: filePath, // Save the file path
        },
      }));
      const updatedFormData = {
        ...formData,
        additionalDocuments: {
          ...formData.additionalDocuments,
          [key]: file,
          [`${key}Path`]: filePath,
        },
      };
      localStorage.setItem('formData', JSON.stringify(updatedFormData)); // Update localStorage
    }
  };

    // Handle file uploads
  const handleFileUpload = (e) => {
    const { name, files } = e.target;
    if (files && files[0]) {
      const file = files[0];
      const filePath = URL.createObjectURL(file); // Generate a temporary URL for the file
      const updatedFormData = { ...formData, [name]: file, [`${name}Path`]: filePath };
      setFormData(updatedFormData);
      localStorage.setItem('formData', JSON.stringify(updatedFormData));
    }
  };

  
  // Handle input field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData, [name]: value };
    setFormData(updatedFormData);
    localStorage.setItem('formData', JSON.stringify(updatedFormData));
  };
  

  // Handle checkbox or boolean input changes
  const handleBooleanChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };



  const saveSignature = () => {
    if (signatureRef.current) {
      const signatureData = signatureRef.current.toDataURL();
      setFormData((prev) => ({ ...prev, applicantSignature: signatureData }));
    }
  };
  
  const clearSignature = () => {
    if (signatureRef.current) {
      signatureRef.current.clear();
      setFormData((prev) => ({
        ...prev,
        applicantSignature: '',
      }));
      const updatedFormData = { ...formData, applicantSignature: '' };
      localStorage.setItem('formData', JSON.stringify(updatedFormData)); // Update localStorage
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    console.log('Submitting form with:', formData); // Debugging log
  
    // Save signature before validation
    if (signatureRef.current) {
      const signatureData = signatureRef.current.toDataURL();
      if (!formData.applicantSignature) {
        setFormData((prev) => ({ ...prev, applicantSignature: signatureData }));
      }
    }
  
    // Validate required fields
    const requiredFields = [
      { key: 'name', label: 'Name' },
      { key: 'email', label: 'Email' },
      { key: 'phone', label: 'Phone' },
      { key: 'address', label: 'Address' },
      { key: 'licenseNumber', label: 'Driver’s License Number' },
      { key: 'licenseState', label: 'Driver’s License State' },
      { key: 'resume', label: 'Resume' },
      { key: 'ssn', label: 'Social Security Number (SSN)' },
      { key: 'birthdate', label: 'Birthdate' },
    ];
  
    for (const { key, label } of requiredFields) {
      if (!formData[key]) {
        setMessage(`Error: ${label} is required.`);
        return;
      }
    }
  
    // Validate additional documents
    const requiredDocuments = [
      { key: 'cpr', label: 'CPR Certificate' },
      { key: 'tb', label: 'TB Test Results' },
      { key: 'license', label: 'Professional License' },
      { key: 'ssn', label: 'Social Security Number (SSN)' },
      { key: 'dl', label: 'Driver’s License' },
    ];
  
    for (const { key, label } of requiredDocuments) {
      if (!formData.additionalDocuments[key]) {
        setMessage(`Error: ${label} is required.`);
        return;
      }
    }
  
    // Validate signature
    if (!formData.applicantSignature) {
      setMessage('Error: Signature is required.');
      return;
    }
  
    // Validate skills and preferences
    console.log('Validating skills and preferences:', {
      willingToWorkWith: formData.willingToWorkWith,
      experienceWith: formData.experienceWith,
    });
  
    if (formData.willingToWorkWith.length === 0) {
      setMessage('Error: Please select at least one preference for "Willing to Work With."');
      return;
    }
  
    if (formData.experienceWith.length === 0) {
      setMessage('Error: Please select at least one skill for "Experience With."');
      return;
    }
  
    // Prepare data for submission
    const preparedData = {
      ...formData,
      willingToWorkWith: formData.willingToWorkWith.join(', '), // Convert to string for PDF
      experienceWith: formData.experienceWith.join(', '), // Convert to string for PDF
      smoker: formData.smoker.isSmoker
        ? `Yes, ${formData.smoker.perDay || '0'} per day`
        : 'No', // Add smoker details
      transportation: formData.transportation ? 'Yes' : 'No', // Add transportation details
    };
  
    console.log('Prepared data for submission:', preparedData);
  
    // Create a FormData object
    const form = new FormData();
    form.append('resume', formData.resume); // Attach resume
    form.append('signature', formData.applicantSignature); // Attach drawn signature
  
    // Attach additional documents
    Object.entries(formData.additionalDocuments).forEach(([key, file]) => {
      form.append(key, file);
    });
  
    // Attach other form data
    form.append('formData', JSON.stringify(preparedData));
  
    try {
      // Submit form data to the backend
      const response = await axios.post('https://healthcare.visionkraftconsulting.com/api/jobs/submit', form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessage(`Success: ${response.data.message}`);
    } catch (error) {
      console.error('Submission Error:', error);
  
      if (error.response && error.response.data && error.response.data.message) {
        setMessage(`Error: ${error.response.data.message}`);
      } else {
        setMessage('Error: Failed to submit application. Please try again.');
      }
    }
  };
  
  // Tab navigation handlers
  const nextTab = () => {
    setCurrentTab((prev) => Math.min(prev + 1, 8)); // Update tab limit based on sections
  };

  const prevTab = () => {
    setCurrentTab((prev) => Math.max(prev - 1, 0));
  };

  const renderTabContent = () => {
    switch (currentTab) {
      case 0:
        return (
<div>
<h4>Personal Information</h4>
      <div className="mb-3">
        <label className="form-label">Name:</label>
        <input
          type="text"
          name="name"
          className="form-control"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Email:</label>
        <input
          type="email"
          name="email"
          className="form-control"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Phone:</label>
        <input
          type="tel"
          name="phone"
          className="form-control"
          value={formData.phone}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Mailing Address:</label>
        <textarea
          name="address"
          className="form-control"
          value={formData.address}
          onChange={handleChange}
          rows="3"
          required
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Driver's License Number:</label>
        <input
          type="text"
          name="licenseNumber"
          className="form-control"
          value={formData.licenseNumber}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Driver's License State:</label>
        <input
          type="text"
          name="licenseState"
          className="form-control"
          value={formData.licenseState}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Social Security Number (SSN):</label>
        <input
          type="text"
          name="ssn"
          className="form-control"
          value={formData.ssn}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Birthdate:</label>
        <input
          type="date"
          name="birthdate"
          className="form-control"
          value={formData.birthdate}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Resume:</label>
        <input
          type="file"
          name="resume"
          className="form-control"
          onChange={handleFileUpload}
          required
        />
        {formData.resumePath && (
          <p>
            Uploaded: <a href={formData.resumePath} target="_blank" rel="noopener noreferrer">View Resume</a>
          </p>
        )}
      </div>
      <div className="mb-3">
        <label className="form-label">Cover Letter (Upload):</label>
        <input
          type="file"
          name="coverLetter"
          className="form-control"
          onChange={handleFileUpload}
          required
        />
        {formData.coverLetterPath && (
          <p>
            Uploaded: <a href={formData.coverLetterPath} target="_blank" rel="noopener noreferrer">View Cover Letter</a>
          </p>
        )}
      </div>
      <div className="mb-3">
        <label className="form-label">Write a Cover Letter:</label>
        <textarea
          name="coverLetterText"
          className="form-control"
          value={formData.coverLetterText}
          onChange={handleChange}
          rows="6"
          placeholder="Write your cover letter here if you prefer."
          required
        />
      </div>
</div>
        );
      case 1:
        return (
<div>
<h4>Experience</h4>
{formData.experience.map((exp, index) => (
  <div key={index} className="mb-3">
    <label className="form-label">Organization:</label>
    <input
      type="text"
      name={`organization-${index}`}
      className="form-control"
      value={exp.organization}
      onChange={(e) => {
        const updatedExperience = [...formData.experience];
        updatedExperience[index].organization = e.target.value;
        setFormData({ ...formData, experience: updatedExperience });
      }}
      required
    />

    <label className="form-label">Dates Worked:</label>
    <input
      type="text"
      name={`dates-${index}`}
      className="form-control"
      value={exp.dates}
      onChange={(e) => {
        const updatedExperience = [...formData.experience];
        updatedExperience[index].dates = e.target.value;
        setFormData({ ...formData, experience: updatedExperience });
      }}
      required
    />

    <label className="form-label">Contact:</label>
    <input
      type="text"
      name={`contact-${index}`}
      className="form-control"
      value={exp.contact}
      onChange={(e) => {
        const updatedExperience = [...formData.experience];
        updatedExperience[index].contact = e.target.value;
        setFormData({ ...formData, experience: updatedExperience });
      }}
    />

    <label className="form-label">Phone:</label>
    <input
      type="tel"
      name={`phone-${index}`}
      className="form-control"
      value={exp.phone}
      onChange={(e) => {
        const updatedExperience = [...formData.experience];
        updatedExperience[index].phone = e.target.value;
        setFormData({ ...formData, experience: updatedExperience });
      }}
    />

    <label className="form-label">Contact Allowed:</label>
    <div className="form-check">
      <input
        type="checkbox"
        name={`contactAllowed-${index}`}
        className="form-check-input"
        checked={exp.contactAllowed}
        onChange={(e) => {
          const updatedExperience = [...formData.experience];
          updatedExperience[index].contactAllowed = e.target.checked;
          setFormData({ ...formData, experience: updatedExperience });
        }}
      />
      <label className="form-check-label">Yes</label>
    </div>

    <button
      type="button"
      className="btn btn-danger mt-2"
      onClick={() => {
        const updatedExperience = formData.experience.filter((_, i) => i !== index);
        setFormData({ ...formData, experience: updatedExperience });
      }}
    >
      Remove Experience
    </button>
  </div>
))}
<button
  type="button"
  className="btn btn-primary mt-3"
  onClick={() => {
    const updatedExperience = [
      ...formData.experience,
      { organization: '', dates: '', contact: '', phone: '', contactAllowed: false },
    ];
    setFormData({ ...formData, experience: updatedExperience });
  }}
>
  Add Another Experience
</button>
</div>
        );
      case 2:
        return (
<div>
  <h4>References</h4>
  {formData.references.map((ref, index) => (
    <div key={index} className="mb-3">
      <label className="form-label">Name:</label>
      <input
        type="text"
        name={`name-${index}`}
        className="form-control"
        value={ref.name}
        onChange={(e) => {
          const updatedReferences = [...formData.references];
          updatedReferences[index].name = e.target.value;
          setFormData({ ...formData, references: updatedReferences });
        }}
        required
      />
      <label className="form-label">Position/Title:</label>
      <input
        type="text"
        name={`position-${index}`}
        className="form-control"
        value={ref.title}
        onChange={(e) => {
          const updatedReferences = [...formData.references];
          updatedReferences[index].title = e.target.value;
          setFormData({ ...formData, references: updatedReferences });
        }}
        required
      />
      <label className="form-label">Phone:</label>
      <input
        type="tel"
        name={`phone-${index}`}
        className="form-control"
        value={ref.phone}
        onChange={(e) => {
          const updatedReferences = [...formData.references];
          updatedReferences[index].phone = e.target.value;
          setFormData({ ...formData, references: updatedReferences });
        }}
        required
      />
      <label className="form-label">Dates Known:</label>
      <input
        type="text"
        name={`datesKnown-${index}`}
        className="form-control"
        value={ref.datesKnown}
        onChange={(e) => {
          const updatedReferences = [...formData.references];
          updatedReferences[index].datesKnown = e.target.value;
          setFormData({ ...formData, references: updatedReferences });
        }}
        required
      />
      <button
        type="button"
        className="btn btn-danger mt-2"
        onClick={() => {
          const updatedReferences = formData.references.filter((_, i) => i !== index);
          setFormData({ ...formData, references: updatedReferences });
        }}
      >
        Remove Reference
      </button>
    </div>
  ))}
  <button
    type="button"
    className="btn btn-primary mt-3"
    onClick={() => {
      const updatedReferences = [
        ...formData.references,
        { name: '', title: '', phone: '', datesKnown: '' },
      ];
      setFormData({ ...formData, references: updatedReferences });
    }}
  >
    Add Another Reference
  </button>

  <h4 className="mt-5">Criminal History</h4>
  <label className="form-label">Have you ever been convicted of any felony or misdemeanor offenses?</label>
  <div className="form-check">
    <input
      type="radio"
      id="criminalHistoryYes"
      name="criminalHistory"
      className="form-check-input"
      checked={formData.criminalHistory.hasRecord === true}
      onChange={() =>
        setFormData({
          ...formData,
          criminalHistory: { ...formData.criminalHistory, hasRecord: true },
        })
      }
      required
    />
    <label className="form-check-label" htmlFor="criminalHistoryYes">
      Yes
    </label>
  </div>
  <div className="form-check">
    <input
      type="radio"
      id="criminalHistoryNo"
      name="criminalHistory"
      className="form-check-input"
      checked={formData.criminalHistory.hasRecord === false}
      onChange={() =>
        setFormData({
          ...formData,
          criminalHistory: { ...formData.criminalHistory, hasRecord: false },
        })
      }
      required
    />
    <label className="form-check-label" htmlFor="criminalHistoryNo">
      No
    </label>
  </div>
  {formData.criminalHistory.hasRecord && (
    <div className="mt-3">
      <label className="form-label">If yes, please describe the date and nature of the offense:</label>
      <textarea
        name="criminalHistoryDescription"
        className="form-control"
        value={formData.criminalHistory.description}
        onChange={(e) =>
          setFormData({
            ...formData,
            criminalHistory: { ...formData.criminalHistory, description: e.target.value },
          })
        }
        rows="3"
        placeholder="Felony / misdemeanor description"
        required
      />
    </div>
  )}
</div>
        );
      case 3:
        return (
<div>
  <h4>Skills and Preferences</h4>

  {/* Willing to Work With */}
  <div className="mb-3">
    <label className="form-label">Please check any you are willing to work with:</label>
    <div>
      {[
        "Companionship",
        "Bathing/Dressing",
        "Hoyer Lift",
        "Gait Belt",
        "Incontinence",
        "Driving",
        "Transfer Assist",
        "Smoking",
        "Alzheimer's/Dementia",
        "Males",
        "Females",
        "Dogs",
        "Cats",
      ].map((option) => (
        <div className="form-check form-check-inline" key={option}>
          <input
            className="form-check-input"
            type="checkbox"
            id={`willingToWorkWith-${option}`}
            value={option}
            checked={formData.willingToWorkWith.includes(option)}
            onChange={() => handleCheckboxChange("willingToWorkWith", option)}
          />
          <label className="form-check-label" htmlFor={`willingToWorkWith-${option}`}>
            {option}
          </label>
        </div>
      ))}
    </div>
  </div>

  {/* Experience With */}
  <div className="mb-3">
    <label className="form-label">Please check any you have experience with:</label>
    <div>
      {[
        "Hoyer Lift",
        "Gait Belt",
        "Incontinence",
        "Transfer Assist",
        "Alzheimer's/Dementia",
      ].map((option) => (
        <div className="form-check form-check-inline" key={option}>
          <input
            className="form-check-input"
            type="checkbox"
            id={`experienceWith-${option}`}
            value={option}
            checked={formData.experienceWith.includes(option)}
            onChange={() => handleCheckboxChange("experienceWith", option)}
          />
          <label className="form-check-label" htmlFor={`experienceWith-${option}`}>
            {option}
          </label>
        </div>
      ))}
    </div>
  </div>

  {/* Specialized Training */}
  <div className="mb-3">
    <label className="form-label">List any additional certifications you hold:</label>
    <textarea
      name="certifications"
      className="form-control"
      value={formData.certifications}
      onChange={handleChange}
      rows="3"
    />
  </div>

  <h4>Additional Questions</h4>

  {/* Transportation Question */}
  <div className="mb-3">
    <label className="form-label">Do you have access to reliable transportation?</label>
    <div>
      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="radio"
          id="transportationYes"
          name="transportation"
          value="true"
          checked={formData.transportation === true}
          onChange={() => handleBooleanChange("transportation", true)}
        />
        <label className="form-check-label" htmlFor="transportationYes">
          Yes
        </label>
      </div>
      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="radio"
          id="transportationNo"
          name="transportation"
          value="false"
          checked={formData.transportation === false}
          onChange={() => handleBooleanChange("transportation", false)}
        />
        <label className="form-check-label" htmlFor="transportationNo">
          No
        </label>
      </div>
    </div>
  </div>

  {/* Smoking Question */}
  <div className="mb-3">
    <label className="form-label">Are you a smoker?</label>
    <div>
      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="radio"
          id="smokerYes"
          name="smoker"
          value="true"
          checked={formData.smoker.isSmoker === true}
          onChange={() => setFormData({ ...formData, smoker: { ...formData.smoker, isSmoker: true } })}
        />
        <label className="form-check-label" htmlFor="smokerYes">
          Yes
        </label>
      </div>
      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="radio"
          id="smokerNo"
          name="smoker"
          value="false"
          checked={formData.smoker.isSmoker === false}
          onChange={() => setFormData({ ...formData, smoker: { ...formData.smoker, isSmoker: false, perDay: "" } })}
        />
        <label className="form-check-label" htmlFor="smokerNo">
          No
        </label>
      </div>
    </div>
    {formData.smoker.isSmoker && (
      <div className="mt-2">
        <label className="form-label">If yes, how many per day?</label>
        <input
          type="number"
          name="perDay"
          className="form-control"
          value={formData.smoker.perDay}
          onChange={(e) =>
            setFormData({ ...formData, smoker: { ...formData.smoker, perDay: e.target.value } })
          }
          min="1"
        />
      </div>
    )}
  </div>

  {/* Open Text Questions */}
  <div className="mb-3">
    <label className="form-label">How did you hear about us?</label>
    <textarea
      name="hearAboutUs"
      className="form-control"
      value={formData.hearAboutUs}
      onChange={handleChange}
      rows="2"
    />
  </div>
  <div className="mb-3">
    <label className="form-label">Tell us about recent caregiving experiences:</label>
    <textarea
      name="caregivingExperience"
      className="form-control"
      value={formData.caregivingExperience}
      onChange={handleChange}
      rows="3"
    />
  </div>
  <div className="mb-3">
    <label className="form-label">Why do you want to be a caregiver with us?</label>
    <textarea
      name="motivation"
      className="form-control"
      value={formData.motivation}
      onChange={handleChange}
      rows="3"
    />
  </div>
</div>
        );
      case 4:
        return (
<div>
<h4>Education</h4>
{formData.education.map((edu, index) => (
  <div key={index} className="mb-3">
    <label className="form-label">School Name:</label>
    <input
      type="text"
      name={`education-name-${index}`}
      className="form-control"
      value={edu.name}
      onChange={(e) => {
        const updatedEducation = [...formData.education];
        updatedEducation[index].name = e.target.value;
        setFormData({ ...formData, education: updatedEducation });
      }}
      required
    />

    <label className="form-label">Location:</label>
    <input
      type="text"
      name={`education-location-${index}`}
      className="form-control"
      value={edu.location}
      onChange={(e) => {
        const updatedEducation = [...formData.education];
        updatedEducation[index].location = e.target.value;
        setFormData({ ...formData, education: updatedEducation });
      }}
      required
    />

    <label className="form-label">Major:</label>
    <input
      type="text"
      name={`education-major-${index}`}
      className="form-control"
      value={edu.major}
      onChange={(e) => {
        const updatedEducation = [...formData.education];
        updatedEducation[index].major = e.target.value;
        setFormData({ ...formData, education: updatedEducation });
      }}
      required
    />

    <label className="form-label">Graduated:</label>
    <div className="form-check">
      <input
        type="checkbox"
        name={`education-graduated-${index}`}
        className="form-check-input"
        checked={edu.graduated}
        onChange={(e) => {
          const updatedEducation = [...formData.education];
          updatedEducation[index].graduated = e.target.checked;
          setFormData({ ...formData, education: updatedEducation });
        }}
      />
      <label className="form-check-label">Yes</label>
    </div>

    <label className="form-label">End Date:</label>
    <input
      type="date"
      name={`education-endDate-${index}`}
      className="form-control"
      value={edu.endDate}
      onChange={(e) => {
        const updatedEducation = [...formData.education];
        updatedEducation[index].endDate = e.target.value;
        setFormData({ ...formData, education: updatedEducation });
      }}
    />

    <button
      type="button"
      className="btn btn-danger mt-2"
      onClick={() => {
        const updatedEducation = formData.education.filter((_, i) => i !== index);
        setFormData({ ...formData, education: updatedEducation });
      }}
    >
      Remove Education
    </button>
  </div>
))}
<button
  type="button"
  className="btn btn-primary mt-3"
  onClick={() => {
    const updatedEducation = [
      ...formData.education,
      { name: '', location: '', major: '', graduated: false, endDate: '' },
    ];
    setFormData({ ...formData, education: updatedEducation });
  }}
>
  Add Another Education
</button>
</div>
        );
      case 5:
        return (
<div>
  <h4>General Availability</h4>
  <div className="mb-3">
    <label className="form-label">Are you available for all hours?</label>
    <div className="form-check">
      <input
        type="radio"
        name="allHours"
        id="allHoursYes"
        className="form-check-input"
        checked={formData.availability.allHours === true}
        onChange={() =>
          setFormData({ ...formData, availability: { ...formData.availability, allHours: true } })
        }
        required
      />
      <label className="form-check-label" htmlFor="allHoursYes">Yes</label>
    </div>
    <div className="form-check">
      <input
        type="radio"
        name="allHours"
        id="allHoursNo"
        className="form-check-input"
        checked={formData.availability.allHours === false}
        onChange={() =>
          setFormData({ ...formData, availability: { ...formData.availability, allHours: false } })
        }
        required
      />
      <label className="form-check-label" htmlFor="allHoursNo">No</label>
    </div>
  </div>

  <div className="mb-3">
    <label className="form-label">Please list your availability below:</label>
    {['Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat', 'Sun'].map((day) => (
      <div key={day} className="mb-1">
        <label className="form-label">{day}:</label>
        <input
          type="text"
          name={`availability-${day}`}
          className="form-control"
          value={formData.availability.schedule[day] || ''}
          onChange={(e) =>
            setFormData({
              ...formData,
              availability: {
                ...formData.availability,
                schedule: { ...formData.availability.schedule, [day]: e.target.value },
              },
            })
          }
          required
        />
      </div>
    ))}
  </div>

  <div className="mb-3">
    <label className="form-label">Are you interested in providing Live-In care?</label>
    <div className="form-check">
      <input
        type="radio"
        name="liveIn"
        id="liveInYes"
        className="form-check-input"
        checked={formData.availability.liveIn === true}
        onChange={() =>
          setFormData({ ...formData, availability: { ...formData.availability, liveIn: true } })
        }
        required
      />
      <label className="form-check-label" htmlFor="liveInYes">Yes</label>
    </div>
    <div className="form-check">
      <input
        type="radio"
        name="liveIn"
        id="liveInNo"
        className="form-check-input"
        checked={formData.availability.liveIn === false}
        onChange={() =>
          setFormData({ ...formData, availability: { ...formData.availability, liveIn: false } })
        }
        required
      />
      <label className="form-check-label" htmlFor="liveInNo">No</label>
    </div>
  </div>

  <div className="mb-3">
    <label className="form-label">If yes, choose maximum # of days:</label>
    <input
      type="number"
      name="maxLiveInDays"
      className="form-control"
      value={formData.availability.maxLiveInDays || ''}
      onChange={(e) =>
        setFormData({
          ...formData,
          availability: { ...formData.availability, maxLiveInDays: e.target.value },
        })
      }
    />
  </div>
</div>
        );
      case 6:
        return (
<div>
  <h4>Emergency Contacts</h4>
  {formData.emergencyContacts.map((contact, index) => (
    <div key={index} className="mb-3">
      <label className="form-label">Name:</label>
      <input
        type="text"
        name={`emergencyContact-name-${index}`}
        className="form-control"
        value={contact.name}
        onChange={(e) => {
          const updatedContacts = [...formData.emergencyContacts];
          updatedContacts[index].name = e.target.value;
          setFormData({ ...formData, emergencyContacts: updatedContacts });
        }}
        required
      />
      <label className="form-label">Relationship:</label>
      <input
        type="text"
        name={`emergencyContact-relationship-${index}`}
        className="form-control"
        value={contact.relationship}
        onChange={(e) => {
          const updatedContacts = [...formData.emergencyContacts];
          updatedContacts[index].relationship = e.target.value;
          setFormData({ ...formData, emergencyContacts: updatedContacts });
        }}
        required
      />
      <label className="form-label">Phone:</label>
      <input
        type="tel"
        name={`emergencyContact-phone-${index}`}
        className="form-control"
        value={contact.phone}
        onChange={(e) => {
          const updatedContacts = [...formData.emergencyContacts];
          updatedContacts[index].phone = e.target.value;
          setFormData({ ...formData, emergencyContacts: updatedContacts });
        }}
        required
      />
      <label className="form-label">Alternate Phone:</label>
      <input
        type="tel"
        name={`emergencyContact-altPhone-${index}`}
        className="form-control"
        value={contact.altPhone}
        onChange={(e) => {
          const updatedContacts = [...formData.emergencyContacts];
          updatedContacts[index].altPhone = e.target.value;
          setFormData({ ...formData, emergencyContacts: updatedContacts });
        }}
      />
      <button
        type="button"
        className="btn btn-danger mt-2"
        onClick={() => {
          const updatedContacts = formData.emergencyContacts.filter((_, i) => i !== index);
          setFormData({ ...formData, emergencyContacts: updatedContacts });
        }}
      >
        Remove Contact
      </button>
    </div>
  ))}
  <button
    type="button"
    className="btn btn-primary mt-3"
    onClick={() => {
      const updatedContacts = [
        ...formData.emergencyContacts,
        { name: '', relationship: '', phone: '', altPhone: '' },
      ];
      setFormData({ ...formData, emergencyContacts: updatedContacts });
    }}
  >
    Add Another Contact
  </button>


  <h4 className="mt-5">Required Additional Documents</h4>
<div className="mb-3">
  <label className="form-label">Upload CPR Certificate:</label>
  <input
    type="file"
    className="form-control"
    onChange={(e) => handleSpecificFileChange(e, 'cpr')}
    required
  />
  {formData.additionalDocuments.cprPath && (
    <p className="text-success mt-2">
      Uploaded: <a href={formData.additionalDocuments.cprPath} target="_blank" rel="noopener noreferrer">View File</a>
    </p>
  )}
</div>
<div className="mb-3">
  <label className="form-label">Upload TB Test Results:</label>
  <input
    type="file"
    className="form-control"
    onChange={(e) => handleSpecificFileChange(e, 'tb')}
    required
  />
  {formData.additionalDocuments.tbPath && (
    <p className="text-success mt-2">
      Uploaded: <a href={formData.additionalDocuments.tbPath} target="_blank" rel="noopener noreferrer">View File</a>
    </p>
  )}
</div>
<div className="mb-3">
  <label className="form-label">Upload Licenses (Professional, Driver's, etc.):</label>
  <input
    type="file"
    className="form-control"
    onChange={(e) => handleSpecificFileChange(e, 'license')}
    accept=".pdf,.png,.jpg,.jpeg"
    required
  />
  {formData.additionalDocuments.licensePath && (
    <p className="text-success mt-2">
      Uploaded: <a href={formData.additionalDocuments.licensePath} target="_blank" rel="noopener noreferrer">View File</a>
    </p>
  )}
</div>
<div className="mb-3">
  <label className="form-label">Upload Social Security Number (SSN):</label>
  <input
    type="file"
    className="form-control"
    onChange={(e) => handleSpecificFileChange(e, 'ssn')}
    required
  />
  {formData.additionalDocuments.ssnPath && (
    <p className="text-success mt-2">
      Uploaded: <a href={formData.additionalDocuments.ssnPath} target="_blank" rel="noopener noreferrer">View File</a>
    </p>
  )}
</div>
<div className="mb-3">
  <label className="form-label">Upload Driver's License:</label>
  <input
    type="file"
    className="form-control"
    onChange={(e) => handleSpecificFileChange(e, 'dl')}
    required
  />
  {formData.additionalDocuments.dlPath && (
    <p className="text-success mt-2">
      Uploaded: <a href={formData.additionalDocuments.dlPath} target="_blank" rel="noopener noreferrer">View File</a>
    </p>
  )}
</div>


<h4 className="mt-5">Certification and Release</h4>
<p className="text-muted">
  I certify the above stated and indicated are true in fact and no misrepresentation of myself has been made. I understand
  that any false information, omissions, or misrepresentation of facts will result in rejection from this application and/or
  discharge at any time during the employment period. I authorize HEARTS UNITED HOMECARE to verify any and all information contained
  within this application, including but not limited to, criminal history and motor vehicle driving records. I authorize all
  persons, schools, companies, and law enforcement authorities to release any information concerning my background and hereby
  release any said persons, schools, companies, and law enforcement authorities from any liability for any damage whatsoever
  for issuing this information. I also understand that the use of illegal drugs is prohibited during employment and that I am
  willing to submit to drug testing at any time to detect the use of illegal drugs prior to or during employment.
</p>
<label className="form-label">Do you agree?</label>
<div className="form-check">
  <input
    type="radio"
    name="certificationAgree"
    id="certificationAgreeYes"
    className="form-check-input"
    checked={formData.certificationAgree === true}
    onChange={() =>
      setFormData({ ...formData, certificationAgree: true })
    }
    required
  />
  <label className="form-check-label" htmlFor="certificationAgreeYes">
    Yes
  </label>
</div>
<div className="form-check">
  <input
    type="radio"
    name="certificationAgree"
    id="certificationAgreeNo"
    className="form-check-input"
    checked={formData.certificationAgree === false}
    onChange={() =>
      setFormData({ ...formData, certificationAgree: false })
    }
    required
  />
  <label className="form-check-label" htmlFor="certificationAgreeNo">
    No
  </label>
</div>

<h4 className="mt-5">Restrictive Covenant</h4>
<p className="text-muted">
  I agree not to do business directly with any individual or business entity that HEARTS UNITED HOMECARE has introduced to me or by
  entering into employment with such individuals or businesses.
</p>
<label className="form-label">Do you agree?</label>
<div className="form-check">
  <input
    type="radio"
    name="restrictiveCovenantAgree"
    id="restrictiveCovenantAgreeYes"
    className="form-check-input"
    checked={formData.restrictiveCovenantAgree === true}
    onChange={() =>
      setFormData({ ...formData, restrictiveCovenantAgree: true })
    }
    required
  />
  <label className="form-check-label" htmlFor="restrictiveCovenantAgreeYes">
    Yes
  </label>
</div>
<div className="form-check">
  <input
    type="radio"
    name="restrictiveCovenantAgree"
    id="restrictiveCovenantAgreeNo"
    className="form-check-input"
    checked={formData.restrictiveCovenantAgree === false}
    onChange={() =>
      setFormData({ ...formData, restrictiveCovenantAgree: false })
    }
    required
  />
  <label className="form-check-label" htmlFor="restrictiveCovenantAgreeNo">
    No
  </label>
</div>

<h4 className="mt-5">Signature</h4>
<div className="mb-3">
  <label className="form-label">Draw Your Signature:</label>
  <div
    style={{
      border: '1px solid #ccc',
      borderRadius: '5px',
      width: '100%',
      height: '200px',
      marginBottom: '10px',
      position: 'relative',
    }}
  >
    <SignatureCanvas
    penColor="blue"
    canvasProps={{
        width: 500,
        height: 200,
        className: 'sigCanvas',
    }}
    ref={signatureRef}
    />
  </div>
  <button
  type="button"
  className="btn btn-secondary"
  onClick={clearSignature} // Use the existing `clearSignature` function
>
  Clear Signature
</button>
</div>

<div className="alert alert-danger mt-4">
  <strong>Note:</strong> All required documents must be uploaded before submission.
</div>
</div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="container mt-5">
      <h1 className="mb-4">Job Application</h1>

      {/* Tab Menu */}
      <ul className="nav nav-tabs mb-3">
        <li className="nav-item">
          <button
            className={`nav-link ${currentTab === 0 ? 'active' : ''}`}
            onClick={() => setCurrentTab(0)}
          >
            Personal Info
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${currentTab === 1 ? 'active' : ''}`}
            onClick={() => setCurrentTab(1)}
          >
            Experience
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${currentTab === 2 ? 'active' : ''}`}
            onClick={() => setCurrentTab(2)}
          >
            References
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${currentTab === 3 ? 'active' : ''}`}
            onClick={() => setCurrentTab(3)}
          >
            Skills
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${currentTab === 4 ? 'active' : ''}`}
            onClick={() => setCurrentTab(4)}
          >
            Education
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${currentTab === 5 ? 'active' : ''}`}
            onClick={() => setCurrentTab(5)}
          >
            Availability
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${currentTab === 6 ? 'active' : ''}`}
            onClick={() => setCurrentTab(6)}
          >
            Emergency Contacts
          </button>
        </li>
      </ul>

      <form onSubmit={handleSubmit}>
        {/* Render Tab Content */}
        {renderTabContent()}

        {/* Navigation Buttons */}
        <div className="d-flex justify-content-between mt-4">
          {currentTab > 0 && (
            <button type="button" className="btn btn-secondary" onClick={prevTab}>
              Back
            </button>
          )}
          {currentTab < 6 ? (
            <button type="button" className="btn btn-primary" onClick={nextTab}>
              Next
            </button>
          ) : (
            <button type="submit" className="btn btn-success">
              Submit Application
            </button>
          )}
        </div>
      </form>

      {/* Success/Error Message */}
      {message && <p className="mt-4 alert alert-info">{message}</p>}
    </div>
  );
}

export default JobApplicationPage;
