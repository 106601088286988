import React, { useState } from 'react';
import axios from 'axios';

function ClockInOut() {
  const [status, setStatus] = useState('Not Clocked In');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [clockInId, setClockInId] = useState(null); // Save clock-in ID for clock-out
  const [location, setLocation] = useState(null); // Save user location
  const [address, setAddress] = useState(''); // Save address from reverse geocoding

  // Function to get user's location
  const getLocation = async () => {
    console.log('[INFO] Fetching geolocation...');
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            console.log('[INFO] Geolocation fetched successfully.');
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          },
          (err) => {
            console.error('[ERROR] Geolocation error:', err.message);
            reject(new Error('Failed to get location: ' + err.message));
          }
        );
      } else {
        const errorMessage = 'Geolocation is not supported by this browser.';
        console.error('[ERROR]', errorMessage);
        reject(new Error(errorMessage));
      }
    });
  };

  // Function to fetch address from latitude and longitude
  const fetchAddress = async (latitude, longitude) => {
    try {
      console.log('[INFO] Fetching address from coordinates...');
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=YOUR_GOOGLE_MAPS_API_KEY`
      );
  
      const results = response.data.results;
      if (results && results.length > 0) {
        console.log('[INFO] Address fetched successfully.');
        return results[0].formatted_address; // Get the first address
      } else {
        console.warn('[WARN] No address found for the given coordinates.');
        return 'Address not found';
      }
    } catch (err) {
      console.error('[ERROR] Failed to fetch address:', err.message);
      return 'Failed to fetch address';
    }
  };

  // Handle Clock In
  const handleClockIn = async () => {
    setLoading(true);
    setError('');
  
    try {
      console.log('[INFO] Initiating Clock-In process...');
      const userLocation = await getLocation(); // Fetch geolocation
      setLocation(userLocation); // Save location for display
      console.log('[INFO] Location:', userLocation);
  
      const token = localStorage.getItem('authToken');
      if (!token) {
        throw new Error('Please log in to continue.');
      }
  
      console.log('[INFO] Sending clock-in request to backend...');
      const response = await axios.post(
        'https://healthcare.visionkraftconsulting.com/api/clock-in',
        {
          latitude: userLocation.latitude,
          longitude: userLocation.longitude,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      console.log('[INFO] Clock-In successful:', response.data);
      setStatus('Clocked In');
      setClockInId(response.data.clockInId); // Save clock-in ID for clock-out
    } catch (err) {
      console.error('[ERROR] Clock-In failed:', err.response?.data || err.message);
      setError(err.response?.data?.message || err.message || 'Clock-In Failed');
    } finally {
      setLoading(false); // Ensure loading is cleared
    }
  };

  // Handle Clock Out
  const handleClockOut = async () => {
    if (!clockInId) {
      setError('No active clock-in record found.');
      return;
    }

    setLoading(true);
    setError('');

    try {
      console.log('[INFO] Initiating Clock-Out process...');
      const token = localStorage.getItem('authToken'); // Retrieve auth token

      const response = await axios.post(
        'https://healthcare.visionkraftconsulting.com/api/clock-out',
        {
          clockInId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log('[INFO] Clock-Out successful:', response.data);
      setStatus('Clocked Out');
      setClockInId(null); // Clear clock-in ID after successful clock-out
      setLocation(null); // Clear location after clock-out
      setAddress(''); // Clear address after clock-out
    } catch (err) {
      console.error('[ERROR] Clock-Out failed:', err.response?.data || err.message);
      setError(err.response?.data?.message || 'Clock-Out Failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1>Clock In/Out</h1>
      <p>Status: {status}</p>
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {location && (
        <div>
          <h3>Your Location:</h3>
          <p>
            Latitude: {location.latitude.toFixed(6)} <br />
            Longitude: {location.longitude.toFixed(6)}
          </p>
          {address && <p><strong>Nearest Address:</strong> {address}</p>}
        </div>
      )}

      <button
        onClick={handleClockIn}
        disabled={loading || status === 'Clocked In'}
      >
        {loading && status === 'Not Clocked In' ? 'Clocking In...' : 'Clock In'}
      </button>
      <button
        onClick={handleClockOut}
        disabled={loading || !clockInId}
      >
        {loading && status === 'Clocked In' ? 'Clocking Out...' : 'Clock Out'}
      </button>

      <p>Debug Info:</p>
      <ul>
        <li>Status: {status}</li>
        <li>Loading: {loading.toString()}</li>
        <li>ClockIn ID: {clockInId}</li>
      </ul>
    </div>
  );
}

export default ClockInOut;