import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import ClientForm from './pages/ClientForm';
import Dashboard from './pages/Dashboard';
import PaymentPage from './pages/PaymentPage';
import JobApplicationPage from './pages/JobApplicationPage';
import Application from './pages/Application';
import ClockInOut from './pages/ClockInOut'; // Import ClockInOut page
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Check authentication state on initial load
  useEffect(() => {
    const authState = localStorage.getItem('isAuthenticated');
    setIsAuthenticated(authState === 'true');
  }, []);

  // Handle login and logout
  const login = () => {
    setIsAuthenticated(true);
    localStorage.setItem('isAuthenticated', 'true');
  };

  const logout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated');
  };

  return (
    <Router>
      {/* Header with Bootstrap styling */}
      <header className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img
              src="https://visionkraft-bucket.s3.us-east-1.amazonaws.com/logos/huhc/logoNEW.png"
              alt="Logo"
              style={{ height: '40px' }}
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              {isAuthenticated ? (
                <>
                  <li className="nav-item">
                    <Link className="nav-link" to="/dashboard">
                      Dashboard
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Options
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li>
                        <Link className="dropdown-item" to="/client-form">
                          Client Form
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/payment">
                          Payment
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/job-application">
                          Job Application
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/application">
                          View Applications
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/clock-in-out">
                          Clock In/Out
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <button
                      className="btn btn-outline-secondary"
                      onClick={logout}
                    >
                      Logout
                    </button>
                  </li>
                </>
              ) : (
                <li className="nav-item">
                  <Link className="btn btn-primary" to="/login">
                    Login
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </header>

      {/* Main Content with Routes */}
      <main className="container mt-5">
        <Routes>
          {/* Default redirect based on authentication state */}
          <Route
            path="/"
            element={
              isAuthenticated ? (
                <Navigate to="/dashboard" replace />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />

          {/* Login Page */}
          <Route path="/login" element={<LoginPage onLogin={login} />} />

          {/* Protected Routes */}
          <Route
            path="/dashboard"
            element={
              isAuthenticated ? (
                <Dashboard />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/client-form"
            element={
              isAuthenticated ? (
                <ClientForm />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/payment"
            element={
              isAuthenticated ? (
                <PaymentPage />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/application"
            element={
              isAuthenticated ? (
                <Application />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/clock-in-out"
            element={
              isAuthenticated ? (
                <ClockInOut />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />

          {/* Unprotected Route */}
          <Route path="/job-application" element={<JobApplicationPage />} />

          {/* Catch-All Route */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </main>
    </Router>
  );
}

export default App;