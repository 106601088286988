import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function LoginPage({ onLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // State for error messages
  const [isLoading, setIsLoading] = useState(false); // Loading state for UX
  const navigate = useNavigate(); // Initialize navigate for redirection

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(''); // Clear previous error message
    setIsLoading(true); // Show loading state

    console.log("Attempting to log in with:", { email, password });

    try {
      const url = 'https://healthcare.visionkraftconsulting.com/api/auth/login';
      console.log(`Sending POST request to ${url}`);

      const response = await axios.post(
        url,
        { email, password },
        {
          withCredentials: true, // Send cookies with the request for session handling
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      console.log("Login successful:", response.data);

      // Callback to update authentication state
      if (onLogin) onLogin(response.data.user);

      navigate('/dashboard'); // Redirect to the dashboard after login
    } catch (error) {
      console.error("Login failed:", error);

      // Extract and display error message
      const errorMsg = error.response?.data?.message || 'An unexpected error occurred. Please try again.';
      setErrorMessage(errorMsg);
    } finally {
      setIsLoading(false); // Hide loading state
    }
  };

  return (
    <div className="login-container">
      <h2>Login</h2>
      <form onSubmit={handleSubmit} className="login-form">
        <div className="form-group">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="form-input"
            required
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="form-input"
            required
          />
        </div>
        {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Display error message */}
        <button type="submit" className="submit-button" disabled={isLoading}>
          {isLoading ? 'Logging in...' : 'Login'}
        </button>
      </form>
    </div>
  );
}

export default LoginPage;