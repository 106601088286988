import React, { useRef, useState } from 'react';
import { Tab, Tabs, Form, Button, Table } from 'react-bootstrap';
import SignatureCanvas from 'react-signature-canvas';
import axios from 'axios';
import { useEffect } from 'react';


const getTodayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

// Helper function to format booleans as "Yes" or "No"
const formatBoolean = (value) => (value ? "Yes" : "No");

const generatePDF = async (formData, signatureRefs) => {
  // Extract client name
  const clientName = formData.clientName || 'Unknown Client';

  // Convert each signature canvas to a data URL
  const signatureData = {
    serviceContractSignature: signatureRefs.serviceContractSignatureRef?.current
      ? signatureRefs.serviceContractSignatureRef.current.getTrimmedCanvas().toDataURL('image/png')
      : '',
    signature: signatureRefs.signatureRef?.current
      ? signatureRefs.signatureRef.current.getTrimmedCanvas().toDataURL('image/png')
      : '',
    rateSheetSignature: signatureRefs.rateSheetSignatureRef?.current
      ? signatureRefs.rateSheetSignatureRef.current.getTrimmedCanvas().toDataURL('image/png')
      : '',
    billOfRightsSignature: signatureRefs.billOfRightsSignatureRef?.current
      ? signatureRefs.billOfRightsSignatureRef.current.getTrimmedCanvas().toDataURL('image/png')
      : '',
    representativeSignature: signatureRefs.representativeSignatureRef?.current
      ? signatureRefs.representativeSignatureRef.current.getTrimmedCanvas().toDataURL('image/png')
      : '',
    complaintProcedureSignature: signatureRefs.complaintProcedureSignatureRef?.current
      ? signatureRefs.complaintProcedureSignatureRef.current.getTrimmedCanvas().toDataURL('image/png')
      : '',
    creditCardSignature: signatureRefs.creditCardSignatureRef?.current
      ? signatureRefs.creditCardSignatureRef.current.getTrimmedCanvas().toDataURL('image/png')
      : '',
    hipaaSignature: signatureRefs.hipaaSignatureRef?.current
      ? signatureRefs.hipaaSignatureRef.current.getTrimmedCanvas().toDataURL('image/png')
      : '',
    vehicleAuthorizationSignature: signatureRefs.vehicleAuthorizationSignatureRef?.current
      ? signatureRefs.vehicleAuthorizationSignatureRef.current.getTrimmedCanvas().toDataURL('image/png')
      : '',
    agencyVehicleAuthorizationSignature: signatureRefs.agencyVehicleAuthorizationSignatureRef?.current
      ? signatureRefs.agencyVehicleAuthorizationSignatureRef.current.getTrimmedCanvas().toDataURL('image/png')
      : '',
    staffRateSheetSignature: signatureRefs.staffRateSheetSignatureRef?.current
      ? signatureRefs.staffRateSheetSignatureRef.current.getTrimmedCanvas().toDataURL('image/png')
      : '',
  };

  // Generate a readable format for formData
  const readableFormData = Object.keys(formData).reduce((acc, key) => {
    const value = formData[key];
    if (typeof value === "boolean") {
      acc[key] = value ? "Yes" : "No"; // Convert booleans to "Yes" or "No"
    } else {
      acc[key] = value || "N/A"; // Convert empty values to "N/A"
    }
    return acc;
  }, {});

  try {
    console.log("Payload sent to generate-pdf:", {
      formData: readableFormData,
      signatureRefs: signatureData,
      clientName, // Include client name in the payload
    });

    // Send formatted form data, signature data, and client name to the backend
    const response = await axios.post(
      'https://healthcare.visionkraftconsulting.com/api/pdf/generate-pdf',
      { formData: readableFormData, signatureRefs: signatureData, clientName },
      { responseType: 'json' } // Expecting a JSON response from the server
    );

    // Log success message or show feedback to the user
    console.log('PDF generation successful:', response.data);
    alert('PDF has been successfully generated and saved to the server.');
  } catch (error) {
    console.error('Error generating PDF:', error.response?.data || error.message);
    alert('Failed to generate PDF');
  }
};

function ClientAdmissionsForm() {
  // Define formData and setFormData with useState
  const [email, setEmail] = useState('');
  const [formData, setFormData] = useState({
    agreeToWelcomeLetter: false,
    agreeToServiceContract: false,
    agreeToRateSheet: false,
    agreeToBillOfRights: false,
    agreeToComplaintProcedure: false,
    agreeToCreditCardAuth: false,
    agreeToHipaa: false,
    signature: '',
    rateSheetSignature: '',
    billOfRightsSignature: '',
    representativeSignature: '',
    complaintProcedureSignature: '',
    complaintRepresentativeSignature: '',
    serviceContractSignature: '',
    creditCardSignatureRef: '',
    hipaaSignatureRef: '',
    vehicleAuthorizationSignatureRef: '',
    agencyVehicleAuthorizationSignatureRef: '',
    vehicleMake: '',
    vehicleModel: '',
    vehicleColorDescription: '',
    vehicleLicensePlate: '',
    vehicleAuthorizationDate: '',
    agreeToVehicleUse: false,
    staffRateSheetSignatureRef: '',
    signatureRefs: '',
  });

  
  // Signature references for each section
  const signatureRef = useRef();
  const rateSheetSignatureRef = useRef();
  const billOfRightsSignatureRef = useRef();
  const representativeSignatureRef = useRef();
  const complaintProcedureSignatureRef = useRef();
  const complaintRepresentativeSignatureRef = useRef();
  const serviceContractSignatureRef = useRef();
  const creditCardSignatureRef = useRef();  
  const hipaaSignatureRef = useRef(); 
  const vehicleAuthorizationSignatureRef = useRef();
  const agencyVehicleAuthorizationSignatureRef = useRef();
  const staffRateSheetSignatureRef = useRef(null); 
  const signatureRefs = useRef({});

  const [activeTab, setActiveTab] = useState('welcome');
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      welcomeSignatureDate: getTodayDate(),
      serviceContractSignatureDate: getTodayDate(),
      rateSheetSignatureDate: getTodayDate(),
      staffRateSheetSignatureDate: getTodayDate(),
      billOfRightsSignatureDate: getTodayDate(),
      complaintProcedureSignatureDate: getTodayDate(),
      creditCardAuthDate: getTodayDate(),
      hipaaSignatureDate: getTodayDate(),
      vehicleAuthorizationDate: getTodayDate(),
    }));
  }, []);
  
  // Reset form function
  const resetForm = () => {
    setFormData({
      agreeToWelcomeLetter: false,
      agreeToServiceContract: false,
      agreeToRateSheet: false,
      agreeToBillOfRights: false,
      agreeToComplaintProcedure: false,
      agreeToCreditCardAuth: false,
      agreeToHipaa: false,
      agreeToVehicleUse: false,
      signature: '',
      rateSheetSignature: '',
      billOfRightsSignature: '',
      representativeSignature: '',
      complaintProcedureSignature: '',
      complaintRepresentativeSignature: '',
      serviceContractSignature: '',
      creditCardSignatureRef: '',
      hipaaSignatureRef: '',
      vehicleAuthorizationSignatureRef: '',
      agencyVehicleAuthorizationSignatureRef: '',
      staffRateSheetSignatureRef: '',
      signatureRefs: '',
    });
    [
      signatureRef,
      rateSheetSignatureRef,
      billOfRightsSignatureRef,
      representativeSignatureRef,
      complaintProcedureSignatureRef,
      complaintRepresentativeSignatureRef,
      serviceContractSignatureRef,
      creditCardSignatureRef,
      hipaaSignatureRef,
      vehicleAuthorizationSignatureRef,
      agencyVehicleAuthorizationSignatureRef,
      staffRateSheetSignatureRef,
      signatureRefs
    ].forEach((ref) => {
      if (ref.current) ref.current.clear();
    });
  };

  // Unified function to handle checkbox changes
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  // Unified function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Unified function to clear a specific signature canvas
  const clearSignature = (ref) => {
    if (ref.current) ref.current.clear();
  };

  // Unified function to save a specific signature canvas
  const saveSignature = (ref, key, dateKey) => {
    if (ref.current) {
      const signatureData = ref.current.getTrimmedCanvas().toDataURL('image/png');
      setFormData((prevData) => ({
        ...prevData,
        [key]: signatureData,
        [dateKey]: getTodayDate(), // Autofill today's date for the signature
      }));
    }
  };
  

// Handle form submission
const handleSubmit = async (e) => {
  e.preventDefault();
  console.log('Email:', email);
  console.log('FormData:', formData);

  // Helper function to autofill signature dates
  const autofillDate = (key) => getTodayDate(); // Assuming getTodayDate() returns today's date in YYYY-MM-DD format

  try {
    // Save signatures and move to the next tab based on the current active tab
    if (activeTab === 'welcome') {
      saveSignature(signatureRef, 'signature', 'welcomeSignatureDate'); // Include autofilled date
      setActiveTab('serviceContract');
    } else if (activeTab === 'serviceContract') {
      saveSignature(serviceContractSignatureRef, 'serviceContractSignature', 'serviceContractSignatureDate'); // Autofill date
      // Ensure email is saved in the formData
      setFormData((prevData) => ({
        ...prevData,
        email: formData.email,
      }));
      setActiveTab('rateSheet');
    } else if (activeTab === 'rateSheet') {
      saveSignature(rateSheetSignatureRef, 'rateSheetSignature', 'rateSheetSignatureDate');
      saveSignature(staffRateSheetSignatureRef, 'staffRateSheetSignature', 'staffRateSheetSignatureDate');
      setActiveTab('billOfRights');
    } else if (activeTab === 'billOfRights') {
      saveSignature(billOfRightsSignatureRef, 'billOfRightsSignature', 'billOfRightsSignatureDate');
      saveSignature(representativeSignatureRef, 'representativeSignature', 'representativeSignatureDate');
      setActiveTab('complaintProcedure');
    } else if (activeTab === 'complaintProcedure') {
      saveSignature(complaintProcedureSignatureRef, 'complaintProcedureSignature', 'complaintProcedureSignatureDate');
      saveSignature(complaintRepresentativeSignatureRef, 'complaintRepresentativeSignature', 'complaintRepresentativeSignatureDate');
      setActiveTab('creditCardAuth');
    } else if (activeTab === 'creditCardAuth') {
      saveSignature(creditCardSignatureRef, 'creditCardSignature', 'creditCardAuthDate');
      setActiveTab('assessment');
    } else if (activeTab === 'assessment') {
      Object.keys(signatureRefs.current).forEach((key) => {
        saveSignature(signatureRefs.current[key], key);
      });
      setActiveTab('hipaaPolicy');
    } else if (activeTab === 'hipaaPolicy') {
      saveSignature(hipaaSignatureRef, 'hipaaSignature', 'hipaaSignatureDate');
      setActiveTab('vehicleAuthorization');
    } else if (activeTab === 'vehicleAuthorization') {
      saveSignature(vehicleAuthorizationSignatureRef, 'vehicleAuthorizationSignature', 'vehicleAuthorizationDate');
      saveSignature(agencyVehicleAuthorizationSignatureRef, 'agencyVehicleAuthorizationSignature', 'agencyVehicleAuthorizationDate');
      setActiveTab('signature');
    } else if (activeTab === 'signature') {
      // Final step: Save form and generate PDF
      const finalFormData = { ...formData, email: formData.email || email };

      console.log('Final FormData:', finalFormData);

      // Save the form data to the backend
      const response = await fetch('https://healthcare.visionkraftconsulting.com/api/save-client-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(finalFormData),
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        console.error('Error Response:', errorResponse);
        alert('Error saving form. Please try again.');
        return;
      }

      alert('Form saved successfully');

      // Generate PDF upon successful form submission
      await generatePDF(
        finalFormData,
        {
          signatureRef,
          rateSheetSignatureRef,
          billOfRightsSignatureRef,
          representativeSignatureRef,
          complaintProcedureSignatureRef,
          complaintRepresentativeSignatureRef,
          serviceContractSignatureRef,
          creditCardSignatureRef,
          hipaaSignatureRef,
          vehicleAuthorizationSignatureRef,
          agencyVehicleAuthorizationSignatureRef,
          staffRateSheetSignatureRef,
        },
        finalFormData.email,
        finalFormData.clientName || 'Unknown Client'
      );
    }
  } catch (error) {
    console.error('Error submitting form:', error);
    alert('Error submitting form');
  }
};
  return (
    <div className="full-height-container">
      <h2>Client Admissions Packet</h2>
      <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} id="client-form-tabs" className="mb-3">

        

  {/* Welcome Letter */}
  <Tab eventKey="welcome" title="Welcome Letter">
  <div className="scrollable-content mb-3" style={{ maxHeight: '800px', overflowY: 'scroll' }}>
    <div>
      <h2>Hearts United Homecare</h2>
      <h3>WELCOME TO OUR COMPANY!</h3>
      <p>
        Welcome to <strong>Hearts United Homecare</strong> and thank you for choosing us as your home care provider.
        Our mission is to be the foremost provider of <strong>meaningful and results-oriented home care connections</strong> 
        for seniors and their loved ones. Our vision is to <strong>arm today’s spectrum of caregivers</strong> with hope, 
        high-impact resources, and the promise of a better tomorrow.
      </p>
      <p>
        At Hearts United Homecare, we develop a <strong>personalized care plan</strong> that directs the care and 
        services we provide to you as a client. We want to work together with you and your family to ensure 
        the highest level of care is provided by trained caregivers.
      </p>
      <p>
        Our caregivers are provided training to deliver <strong>quality, person-centered care</strong>. We strive to 
        have consistency in your care team, but staff may have days off that can conflict with scheduled shifts. 
        Our office staff works diligently to ensure the caregiver coming to your home can meet your care needs.
      </p>
      <h4>Office Hours</h4>
      <p>
        Our office is open <strong>Monday through Friday</strong> between the hours of <strong>9 a.m. and 5 p.m.</strong> 
        Our <strong>On-Call Supervisor</strong> is available after these hours, <strong>24 hours per day, 365 days per year</strong> 
        by calling our main phone number.
      </p>
      <p>
        We strive to provide meaningful services to each client, but it is possible that you may have a complaint 
        about our staff or services. Please contact our office at <strong>(253) 298-2874</strong> to express feedback and complaints.
      </p>
      <p>
        There are many home care choices out there, and we sincerely thank you for choosing <strong>Hearts United Homecare</strong>.
        We look forward to working with you.
      </p>
      <h4>Location</h4>
      <p>
        <strong>Hearts United Homecare is located at:</strong><br />
        <u>102020 Pacific Ave S Suite 216</u><br />
        <u>Tacoma, WA 98444</u>
      </p>
      <p>
        <strong>Sincerely,</strong><br />
        Carolyne N, Owner
      </p>
    </div>
  </div>
  <Form.Check
    type="checkbox"
    label="I acknowledge the Welcome Letter"
    name="agreeToWelcomeLetter"
    checked={formData.agreeToWelcomeLetter}
    onChange={handleCheckboxChange}
  />
</Tab>

          
<Tab eventKey="serviceContract" title="Service Contract">
  <div className="scrollable-content mb-3">
    <h2>Hearts United Homecare</h2>
    <h3>Client Services Contract</h3>
    <p>
      The Client Services Contract (“Agreement”) sets forth the terms and conditions under which 
      <strong>Hearts United Homecare</strong> (“Agency”) will provide services to 
      <Form.Control 
        type="text" 
        name="clientName" 
        placeholder="Client Name" 
        value={formData.clientName || ''} 
        onChange={handleInputChange} 
        style={{ display: "inline", width: "auto", marginLeft: "5px", marginRight: "5px" }}
      /> 
      (“Client”). By Client’s or Client Representative’s signature at the bottom of this agreement 
      and/or receipt of services, whichever is first, Client agrees to the terms and conditions in this Agreement.
    </p>

    <h4>Client Information</h4>
    <Form.Group className="mb-2">
      <Form.Label>First Name:</Form.Label>
      <Form.Control 
        type="text" 
        name="firstName" 
        value={formData.firstName || ''} 
        onChange={handleInputChange} 
      />
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>Last Name:</Form.Label>
      <Form.Control 
        type="text" 
        name="lastName" 
        value={formData.lastName || ''} 
        onChange={handleInputChange} 
      />
    </Form.Group>

    {/* Email Field */}
    <Form.Group className="mb-2">
      <Form.Label>Email Address:</Form.Label>
      <Form.Control 
        type="email" 
        name="email" 
        placeholder="Enter email address" 
        value={formData.email || ''} // Bind to formData
        onChange={(e) => {
          const updatedEmail = e.target.value;
          setFormData((prevData) => ({
            ...prevData,
            email: updatedEmail, // Sync email in formData
          }));
        }}
      />
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>Address:</Form.Label>
      <Form.Control 
        type="text" 
        name="address" 
        value={formData.address || ''} 
        onChange={handleInputChange} 
      />
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>City:</Form.Label>
      <Form.Control 
        type="text" 
        name="city" 
        value={formData.city || ''} 
        onChange={handleInputChange} 
      />
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>Phone Number:</Form.Label>
      <Form.Control 
        type="text" 
        name="phoneNumber" 
        value={formData.phoneNumber || ''} 
        onChange={handleInputChange} 
      />
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>Date of Birth:</Form.Label>
      <Form.Control 
        type="date" 
        name="dob" 
        value={formData.dob || ''} 
        onChange={handleInputChange} 
      />
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>Start of Care Date:</Form.Label>
      <Form.Control 
        type="date" 
        name="startOfCareDate" 
        value={formData.startOfCareDate || ''} 
        onChange={handleInputChange} 
      />
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>Days Per Week:</Form.Label>
      <Form.Control 
        type="number" 
        name="daysPerWeek" 
        value={formData.daysPerWeek || ''} 
        onChange={handleInputChange} 
      />
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>Hours Per Day:</Form.Label>
      <Form.Control 
        type="number" 
        name="hoursPerDay" 
        value={formData.hoursPerDay || ''} 
        onChange={handleInputChange} 
      />
    </Form.Group>

    <h4>Terms</h4>
    <p>
      <strong>1. Term of Agreement:</strong> The term of this Agreement will start on the first day that Client receives 
      any Service from Hearts United Homecare (the “Effective Date”) and will continue on an as-needed basis until 
      the Agreement is terminated by either party, as provided in this Agreement.
    </p>

    <p>
      <strong>2. Services Provided:</strong> Hearts United Homecare will provide to Client the services and care outlined 
      in the Client’s Plan of Care (“Services”). Hearts United Homecare is a licensed Home Services Agency in the State 
      of Washington. The Services to be provided to the Client will be one or more of the following, as identified by the 
      Client in a separate addendum to this Agreement.
    </p>
    <ul>
      <li>Homemaker & Companion</li>
      <li>Personal Care</li>
      <li>
        Other 
        <Form.Control 
          type="text" 
          name="otherServices" 
          placeholder="Specify other services" 
          value={formData.otherServices || ''} 
          onChange={handleInputChange} 
          style={{ display: "inline", width: "auto", marginLeft: "5px" }}
        />
      </li>
    </ul>
  </div>

  {/* Checkbox for agreement to Service Contract terms */}
  <Form.Check
    type="checkbox"
    label="I agree to the Service Contract terms"
    name="agreeToServiceContract"
    checked={formData.agreeToServiceContract}
    onChange={handleCheckboxChange}
  />

  {/* Signature Canvas for Service Contract Acknowledgment */}
  <Form.Group className="mb-3">
    <Form.Label>Client or Representative Signature for Service Contract:</Form.Label>
    <SignatureCanvas
      ref={serviceContractSignatureRef}
      penColor="black"
      canvasProps={{ width: 500, height: 200, className: 'border' }}
    />
    <Button variant="secondary" onClick={() => clearSignature(serviceContractSignatureRef)} className="mt-2">Clear Signature</Button>
  </Form.Group>
</Tab>
 {/* Rate Sheet Tab */}
 <Tab eventKey="rateSheet" title="Rate Sheet">
  <div className="scrollable-content mb-3">
    <h3>Rate for Services</h3>
    <p><strong>Rate For Services</strong> (see Fee Schedule for service descriptions):</p>

    <Form.Group className="mb-2">
      <Form.Label>Rate per Hour:</Form.Label>
      <Form.Control 
        type="number" 
        placeholder="$" 
        name="ratePerHour" 
        value={formData.ratePerHour || ''} 
        onChange={handleInputChange} 
      />
      <Form.Text>For Homemaker & Companionship OR Personal Care</Form.Text>
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>Entity Responsible for Paying for Services:</Form.Label>
      <div>
        <Form.Check 
          inline
          label="Hearts United Homecare" 
          name="responsibleParty" 
          type="radio" 
          value="Hearts United Homecare"
          checked={formData.responsibleParty === "Hearts United Homecare"} 
          onChange={handleInputChange} 
        />
        <Form.Check 
          inline 
          label="Client" 
          name="responsibleParty" 
          type="radio" 
          value="Client"
          checked={formData.responsibleParty === "Client"} 
          onChange={handleInputChange} 
        />
        <Form.Check 
          inline 
          label="Other" 
          name="responsibleParty" 
          type="radio" 
          value="Other"
          checked={formData.responsibleParty === "Other"} 
          onChange={handleInputChange} 
        />
        {formData.responsibleParty === "Other" && (
          <Form.Control 
            type="text" 
            placeholder="Specify other payer" 
            name="otherResponsibleParty" 
            value={formData.otherResponsibleParty || ''} 
            onChange={handleInputChange} 
          />
        )}
      </div>
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>Payment Schedule:</Form.Label>
      <Form.Control 
        type="text" 
        placeholder="As outlined in Client Services Contract" 
        name="paymentSchedule" 
        value={formData.paymentSchedule || ''} 
        onChange={handleInputChange} 
      />
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>Method for Making Payments:</Form.Label>
      <div>
        <Form.Check 
          inline 
          label="Check" 
          name="paymentMethod" 
          type="radio" 
          value="Check"
          checked={formData.paymentMethod === "Check"} 
          onChange={handleInputChange} 
        />
        <Form.Check 
          inline 
          label="Credit Card" 
          name="paymentMethod" 
          type="radio" 
          value="Credit Card"
          checked={formData.paymentMethod === "Credit Card"} 
          onChange={handleInputChange} 
        />
        <Form.Check 
          inline 
          label="Other" 
          name="paymentMethod" 
          type="radio" 
          value="Other"
          checked={formData.paymentMethod === "Other"} 
          onChange={handleInputChange} 
        />
        {formData.paymentMethod === "Other" && (
          <Form.Control 
            type="text" 
            placeholder="Specify other method" 
            name="otherPaymentMethod" 
            value={formData.otherPaymentMethod || ''} 
            onChange={handleInputChange} 
          />
        )}
      </div>
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>Initial Deposit:</Form.Label>
      <Form.Control 
        type="number" 
        placeholder="Initial Deposit" 
        name="initialDeposit" 
        value={formData.initialDeposit || ''} 
        onChange={handleInputChange} 
      />
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>Hours/Day X 30 Days:</Form.Label>
      <Form.Control 
        type="text" 
        placeholder="Total hours per month" 
        name="hoursPerMonth" 
        value={
          formData.hoursPerDay && formData.daysPerWeek 
            ? (formData.hoursPerDay * formData.daysPerWeek * 4).toFixed(2)
            : ''
        } 
        readOnly 
      />
    </Form.Group>

    <strong>6. Billing</strong>
    <p>
      Hearts United Homecare will bill monthly and expect payment by Client within thirty (7) days. Any questions 
      regarding your invoice should be directed to Hearts United Homecare. Payment may be made by check, cash, or 
      credit card. An account is considered overdue if not paid within 5 days of the billing date. Interest will be 
      charged on account balances that remain unpaid for 6 days or more at the rate of 5% per month until paid. If 
      Medicaid or another insurance is covering payment, they will be billed first. Client rates may be increased 
      with a 30-day written notice by Hearts United Homecare.
    </p>
    
    <strong>7. Cancellations.</strong>
    <p>
    Cancellations may be made up to 8 hours in advance of a scheduled visit without
    charge. We reserve the right to charge for a scheduled visit if insufficient notice is not given.
    </p>

    
    <strong>8. Termination or Modification</strong>
    <p>
    Either “Client” or “Hearts United Homecare” may terminate this
agreement at any time upon written notice to the other party. Either party may modify or amend the
contract by putting a request in writing with a response in a reasonable time that must be agreed
upon by both parties. If either party terminates this Agreement, all fees due at time of termination
will be due and payable by Client immediately.
<li>A Client or Client Representative may request to modify or amend this agreement by putting
a request in writing to the Hearts United Homecare and specify what is requested to be
modified or amended. Hearts United Homecare will respond in writing to the Client or Client
Representative within a reasonable time, but no longer than 7 days, and provide an
explanation of Hearts United Homecares decision. The modification or amendment must be
agreed upon by both parties.</li>
<li>When services are terminated by the agency, the client is to be notified at least seven
working days in advance of the date of termination, with a stated reason for the termination.
This information shall be maintained in the client record. The seven-day notice requirement
is not applicable in cases in which the worker’s safety is at risk. In these cases, the agency
may notify the client of termination of services and the reason for termination.
Documentation of the risk to the provider shall be maintained in the client record.
    </li>


    </p>
    <strong>9. Transportation..</strong>
    <p>
    If an employee of Hearts United Homecare transports a client in their own vehicle or
the client’s vehicle, the client will release Hearts United Homecare and/or that employee from all liability should an injury or accident occur. The employee is required to have insurance and the Client
is also required to have insurance.
    </p>
    <strong>10. Private/Direct Hiring.</strong>
    <p>
    The Client may not privately/directly hire a Hearts United Homecare employee
for a period of 180 days following the date that employee last provided services for Client. In the
event the Client breaks this condition, a replacement fee of $10,000 is due to tHearts United
Homecare immediately upon employment of that individual.
    </p>
    <strong>11. Supplies and Equipment.</strong>
    <p>
    The Client is responsible for supplying all supplies (i.e. cleaning, personal
care, etc.) and equipment which may be necessary in the provision of services. Additional charges
may apply if Hearts United Homecare provides supplies and/or equipment.
    </p>

    <strong>12. Regulatory Compliance.</strong>
    <p>
    Hearts United Homecare shall be in compliance with all applicable
regulations and laws pertaining to licensed home services agencies in the State of Washington,
including the Health Care Worker Background Check Act.
    </p>
    <strong>13. Supervision Responsibility.</strong>
    <p>
    Hearts United Homecare shall be in compliance with all applicable
regulations and laws pertaining to licensed home services agencies in the State of Washington,
including the Health Care Worker Background Check Act.
    </p>
    <strong>14. Emergency Notification.</strong>
    <p>
    In the case of an emergency, Hearts United Homecare shall notify the listed
emergency contact on file.
    </p>
    <strong>15. Responsible and Liable Parties.</strong>
    <p>
    Hearts United Homecare is required to provide the consumer
information as to the responsibilities of the agency, the home care worker, and the consumer
regarding the employment, duties and liability of each. Please see Attachment A for a list indicating
the responsible party.
    </p>
    <strong>16. Complaints.</strong>
    <p>
    A grievance or complaint may be filed by calling Hearts United Homecare office and
speaking with any office personnel. Also, a Client or Representative may put a grievance or complaint
in writing and send it to the office. A representative from the Office will contact the Client or
Representative to resolve the complaint within 7 days.
    </p>
    
    <p>
  <strong>Contact Information for Concerns, Complaints, or Questions:</strong><br />
  Hearts United Homecare<br />
  Attn: Carolyne N<br />
  102020 Pacific Ave S Suite 216<br />
  Tacoma, WA 98444
</p>


    <h4>Agreement</h4>
    <p>
      I understand that by signing this Agreement, I have read, reviewed, and agree with the terms and consent to 
      receive services.
    </p>

    {/* Signature Canvas for Rate Sheet */}
    <Form.Group className="mb-3">
      <Form.Label>Signature for Rate Sheet Agreement:</Form.Label>
      <SignatureCanvas
        ref={rateSheetSignatureRef}  // Direct reference usage
        penColor="black"
        canvasProps={{ width: 500, height: 200, className: 'border' }}
      />
      <Button variant="secondary" onClick={() => clearSignature(rateSheetSignatureRef)} className="mt-2">Clear Signature</Button>
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>Date:</Form.Label>
      <Form.Control 
        type="date" 
        name="rateSheetSignatureDate" 
        value={formData.rateSheetSignatureDate || ''} 
        onChange={handleInputChange} 
      />
    </Form.Group>

    <Form.Check
      type="checkbox"
      label="I acknowledge the Rate Sheet"
      name="agreeToRateSheet"
      checked={formData.agreeToRateSheet}
      onChange={handleCheckboxChange}
    />
  </div>
</Tab>

<Tab eventKey="rateSheet" title="Service Rate Sheet">
  <div className="scrollable-content mb-3" style={{ maxHeight: '1200px', overflowY: 'scroll', padding: '15px', border: '1px solid #ddd' }}>
    <h3>Client Service Rate Sheet</h3>
    <p><strong>Services:</strong> Homemaker, Companionship, Personal Care (3 Hour Minimum) - <strong>$35/Hour</strong></p>
    
    <ul>
      <li>ADL Support</li>
      <li>Meal prep/Cooking</li>
      <li>Shopping</li>
      <li>Medication reminding</li>
      <li>Assistance to and from appointments</li>
      <li>Maintenance of household records</li>
      <li>Light Housekeeping</li>
      <li>Laundry</li>
      <li>Alzheimer’s and Dementia Care</li>
      <li>Respite Care</li>
      <li>Companionship</li>
      <li>Feeding assistance</li>
      <li>Bathing/Toileting/Hair Care/Shaving</li>
      <li>Skin Care/Nail Care/Mouth Care</li>
      <li>Hygiene/Continence Care</li>
      <li>Dressing</li>
      <li>Ambulation Assistance</li>
      <li>Exercise and Transfers</li>
      <li>Positioning</li>
    </ul>

    <p><em>*Holidays are billed at 1.5 times the normal rate:</em> New Year’s Day, Memorial Day, Independence Day, Labor Day, Thanksgiving, Christmas Eve, Christmas Day, Easter</p>

    {/* Signature Canvas for Client/Authorized Representative */}
    <h5>Signature</h5>
    <Form.Group className="mb-3">
      <Form.Label>Client/Authorized Representative Signature:</Form.Label>
      <SignatureCanvas
        ref={rateSheetSignatureRef}
        penColor="black"
        canvasProps={{ width: 500, height: 200, className: 'border' }}
      />
      <Button variant="secondary" onClick={() => clearSignature(rateSheetSignatureRef)} className="mt-2">Clear Signature</Button>
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>Date:</Form.Label>
      <Form.Control type="date" name="rateSheetSignatureDate" value={formData.rateSheetSignatureDate || ''} onChange={handleInputChange} />
    </Form.Group>

    {/* Staff Representative Signature */}
    <Form.Group className="mb-3">
      <Form.Label>Staff Representative Signature:</Form.Label>
      <SignatureCanvas
        ref={staffRateSheetSignatureRef}
        penColor="black"
        canvasProps={{ width: 500, height: 200, className: 'border' }}
      />
      <Button variant="secondary" onClick={() => clearSignature(staffRateSheetSignatureRef)} className="mt-2">Clear Signature</Button>
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>Date:</Form.Label>
      <Form.Control type="date" name="staffRateSheetSignatureDate" value={formData.staffRateSheetSignatureDate || ''} onChange={handleInputChange} />
    </Form.Group>
  </div>
</Tab>

        {/* Bill of Rights */}
        <Tab eventKey="billOfRights" title="Bill of Rights">
  <div className="mb-3" style={{ maxHeight: '1200px', overflowY: 'scroll' }}>
    <h3>Client Bill of Rights</h3>
    <p><strong>POLICY:</strong> Hearts United Homecare at the time of admission must provide each client, designated family member, or legal representative with a written bill of rights affirming each client's rights.</p>
    <ul>
      <li>1. Receive quality services from Hearts United Homecare for services identified in the plan of care;</li>
      <li>2. Be cared for by appropriately trained or credentialed personnel, contractors, and volunteers with coordination of services;</li>
      <li>3. A statement advising of the right to ongoing participation in the development of the plan of care;</li>
      <li>4. A statement advising of the right to have access to the Department of Health's listing of licensed home care agencies and to select any licensee to provide care, subject to the individual's reimbursement mechanism or other relevant contractual obligations;</li>
      <li>5. A listing of the total services offered by Hearts United Homecare and those being provided to the client;</li>
      <li>6. Refuse specific services;</li>
      <li>7. The name of the individual within Hearts United Homecare responsible for supervising the client's care and the manner in which that individual may be contacted;</li>
      <li>8. Be treated with courtesy, respect, and privacy;</li>
      <li>9. Be free from verbal, mental, sexual, and physical abuse, neglect, exploitation, and discrimination;</li>
      <li>10. Have property treated with respect;</li>
      <li>11. Privacy and confidentiality of personal information and health care-related records;</li>
      <li>12. Be informed of what Hearts United Homecare charges for services, to what extent payment may be expected from care insurance, public programs, or other sources, and what charges the client may be responsible for paying;</li>
      <li>13. A fully itemized billing statement upon request, including the date of each service and the charge;</li>
      <li>14. Be informed about advanced directives and POLST, and Hearts United Homecare's scope of responsibility;</li>
      <li>15. Be informed of Hearts United Homecare's policies and procedures regarding the circumstances that may cause the agency to discharge a client;</li>
      <li>16. Be informed of Hearts United Homecare's policies and procedures for providing back-up care when services cannot be provided as scheduled;</li>
      <li>17. A description of Hearts United Homecare's process for clients and family to submit complaints to the home care agency about the services and care they are receiving and to have those complaints addressed without retaliation;</li>
      <li>18. Be informed of the Department of Health's complaint hotline number to report complaints about the licensed agency or credentialed health care professionals (1-800-633-6828);</li>
      <li>19. Be informed of the DSHS end harm hotline number to report suspected abuse of children or vulnerable adults (1-866-363-4276);</li>
      <li>20. Hearts United Homecare will ensure that the client rights under this policy are implemented and updated as appropriate.</li>
    </ul>
    <p>If you believe your rights have been violated, you may contact Hearts United Homecare directly:</p>
    <p>
      <strong>Attn:</strong> Carolyne N, Hearts United Homecare, 102020 Pacific Ave S Suite 216 Tacoma, WA 98444<br/>
      <strong>Phone:</strong> (253) 298-2874
    </p>
    <p>I attest to verbal and written receipt of the aforementioned notice of rights:</p>

    {/* Signature Canvas for Client/Representative Signature */}
    <Form.Group className="mb-3">
      <Form.Label>Consumer or Authorized Representative Signature:</Form.Label>
      <SignatureCanvas
        ref={billOfRightsSignatureRef}
        penColor="black"
        canvasProps={{ width: 500, height: 200, className: 'border' }}
      />
      <Button variant="secondary" onClick={() => clearSignature(billOfRightsSignatureRef)} className="mt-2">Clear Signature</Button>
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>Date:</Form.Label>
      <Form.Control 
        type="date" 
        name="billOfRightsSignatureDate" 
        value={formData.billOfRightsSignatureDate || ''} 
        onChange={handleInputChange} 
      />
    </Form.Group>

    {/* Signature Canvas for Hearts United Homecare Representative Signature */}
    <Form.Group className="mb-3">
      <Form.Label>Hearts United Homecare Representative Signature:</Form.Label>
      <SignatureCanvas
        ref={representativeSignatureRef}
        penColor="black"
        canvasProps={{ width: 500, height: 200, className: 'border' }}
      />
      <Button variant="secondary" onClick={() => clearSignature(representativeSignatureRef)} className="mt-2">Clear Signature</Button>
    </Form.Group>

    <Form.Check
      type="checkbox"
      label="I agree to the Client Bill of Rights"
      name="agreeToBillOfRights"
      checked={formData.agreeToBillOfRights}
      onChange={handleCheckboxChange}
    />
  </div>
</Tab>
        {/* Complaint Procedure */}
        <Tab eventKey="complaintProcedure" title="Complaint Procedure">
  <div className="mb-3" style={{ maxHeight: '1200px', overflowY: 'scroll' }}>
    <h3>Client Complaint Procedure Notice</h3>
    <p>Hearts United Homecare takes client concerns and complaints seriously. A Client or Client’s Representative may present complaints, either orally or in writing, about services and shall have their complaints addressed and resolved as appropriate by the agency in a timely manner.</p>
    <p>If a Client or Responsible Party has any complaints about services being provided, information, or questions, you may contact the agency at:</p>
    <p>
      <strong>Hearts United Homecare</strong><br/>
      102020 Pacific Ave S Suite 216<br/>
      Tacoma, WA 98444<br/>
      <strong>Phone:</strong> (253) 298-2874
    </p>
    
    <h4>Procedure:</h4>
    <ul>
      <li>Client and/or Client’s Representative will be provided the process to file a complaint/concern with the agency upon admission.</li>
      <li>A complaint/concern about services being performed by the agency can be provided by a Client and/or Client’s Representative verbally or in writing.</li>
      <li>The Administrator will conduct an investigation of the complaint and determine a resolution.</li>
      <li>Complaints will be documented and provided to the Administrator or designee, who will provide a response and proposed solution within 10 days.</li>
      <li>The Client and/or Client’s Representative will be provided a right to appeal the decision if requested, which shall be reviewed by the Governing Body.</li>
      <li>The Governing Body shall review the complaint and provide a determination in writing within 30 days.</li>
      <li>The Client and/or Family Member is able to make a complaint to the Washington Department of Public Health at:
        <p><strong>Washington Department of Public Health, Health Systems Quality Assurance Complaint Intake</strong><br/>
        P.O. Box 47857, Olympia, WA 98504-7857<br/>
        <strong>Phone:</strong> (360) 236-4700</p>
      </li>
    </ul>
    <p>Hearts United Homecare does not discriminate or retaliate against a Client for expressing a complaint or multiple complaints.</p>

    <Form.Check
      type="checkbox"
      label="I agree to the Complaint Procedure"
      name="agreeToComplaintProcedure"
      checked={formData.agreeToComplaintProcedure}
      onChange={handleCheckboxChange}
    />

    {/* Signature Canvas for Client or Responsible Party Signature */}
    <Form.Group className="mb-3">
      <Form.Label>Client or Responsible Party Signature:</Form.Label>
      <SignatureCanvas
        ref={complaintProcedureSignatureRef}
        penColor="black"
        canvasProps={{ width: 500, height: 200, className: 'border' }}
      />
      <Button variant="secondary" onClick={() => clearSignature(complaintProcedureSignatureRef)} className="mt-2">Clear Signature</Button>
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>Date:</Form.Label>
      <Form.Control 
        type="date" 
        name="complaintProcedureSignatureDate" 
        value={formData.complaintProcedureSignatureDate || ''} 
        onChange={handleInputChange} 
      />
    </Form.Group>

    {/* Signature Canvas for Hearts United Homecare Representative Signature */}
    <Form.Group className="mb-3">
      <Form.Label>Hearts United Homecare Representative Signature:</Form.Label>
      <SignatureCanvas
        ref={complaintRepresentativeSignatureRef}
        penColor="black"
        canvasProps={{ width: 500, height: 200, className: 'border' }}
      />
      <Button variant="secondary" onClick={() => clearSignature(complaintRepresentativeSignatureRef)} className="mt-2">Clear Signature</Button>
    </Form.Group>
  </div>
</Tab>

        {/* Credit Card Authorization */}
        <Tab eventKey="creditCardAuth" title="Credit Card Authorization">
  <div className="mb-3" style={{ maxHeight: '1200px', overflowY: 'scroll' }}>
    <h3>Credit Card Authorization Form</h3>
    <p>Hearts United Homecare offers the ability to provide a credit card to keep on file in order to automatically process your invoices or make individual payments as desired. Please complete this information and sign where indicated.</p>

    {/* Client Name */}
    <Form.Group className="mb-2">
      <Form.Label>Client Name:</Form.Label>
      <Form.Control 
        type="text" 
        name="clientName" 
        value={formData.clientName || ''} 
        onChange={handleInputChange} 
      />
    </Form.Group>

    {/* Client Address */}
    <Form.Group className="mb-2">
      <Form.Label>Client Address:</Form.Label>
      <Form.Control 
        type="text" 
        name="clientAddress" 
        value={formData.clientAddress || ''} 
        onChange={handleInputChange} 
      />
    </Form.Group>

    {/* Phone Number */}
    <Form.Group className="mb-2">
      <Form.Label>Phone Number:</Form.Label>
      <Form.Control 
        type="text" 
        name="phoneNumber" 
        value={formData.phoneNumber || ''} 
        onChange={handleInputChange} 
      />
    </Form.Group>

    {/* Billing Name/Cardholder Name */}
    <Form.Group className="mb-2">
      <Form.Label>Billing Name/Cardholder Name:</Form.Label>
      <Form.Control 
        type="text" 
        name="cardholderName" 
        value={formData.cardholderName || ''} 
        onChange={handleInputChange} 
      />
    </Form.Group>

    {/* Billing Address */}
    <Form.Group className="mb-2">
      <Form.Label>Billing Address:</Form.Label>
      <Form.Control 
        type="text" 
        name="billingAddress" 
        value={formData.billingAddress || ''} 
        onChange={handleInputChange} 
      />
    </Form.Group>

    {/* Cardholder Telephone */}
    <Form.Group className="mb-2">
      <Form.Label>Cardholder Telephone (Home):</Form.Label>
      <Form.Control 
        type="text" 
        name="cardholderPhoneHome" 
        value={formData.cardholderPhoneHome || ''} 
        onChange={handleInputChange} 
      />
      <Form.Label className="mt-2">Cardholder Telephone (Cell/Work):</Form.Label>
      <Form.Control 
        type="text" 
        name="cardholderPhoneCellWork" 
        value={formData.cardholderPhoneCellWork || ''} 
        onChange={handleInputChange} 
      />
    </Form.Group>

    {/* Credit Card Type */}
    <Form.Group className="mb-2">
      <Form.Label>Type of Credit Card:</Form.Label>
      <div>
        <Form.Check 
          inline 
          label="Visa" 
          type="radio" 
          name="creditCardType" 
          value="Visa" 
          checked={formData.creditCardType === 'Visa'}
          onChange={handleInputChange} 
        />
        <Form.Check 
          inline 
          label="MasterCard" 
          type="radio" 
          name="creditCardType" 
          value="MasterCard" 
          checked={formData.creditCardType === 'MasterCard'}
          onChange={handleInputChange} 
        />
        <Form.Check 
          inline 
          label="Discover" 
          type="radio" 
          name="creditCardType" 
          value="Discover" 
          checked={formData.creditCardType === 'Discover'}
          onChange={handleInputChange} 
        />
        <Form.Check 
          inline 
          label="American Express" 
          type="radio" 
          name="creditCardType" 
          value="American Express" 
          checked={formData.creditCardType === 'American Express'}
          onChange={handleInputChange} 
        />
      </div>
    </Form.Group>

    {/* Card Number, Expiration Date, and Card Verification */}
    <Form.Group className="mb-2">
      <Form.Label>Card Number:</Form.Label>
      <Form.Control 
        type="text" 
        name="cardNumber" 
        value={formData.cardNumber || ''} 
        onChange={handleInputChange} 
      />
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>Expiration Date:</Form.Label>
      <Form.Control 
        type="text" 
        name="expirationDate" 
        value={formData.expirationDate || ''} 
        onChange={handleInputChange} 
      />
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>Card Verification #:</Form.Label>
      <Form.Control 
        type="text" 
        name="cardVerification" 
        value={formData.cardVerification || ''} 
        onChange={handleInputChange} 
      />
    </Form.Group>

    {/* Payment Authorizations */}
    <h4>Payment Authorizations</h4>

    {/* Initial Deposit Payment Authorization */}
    <Form.Group className="mb-2">
      <Form.Label>Initial Deposit Payment Authorization (Optional):</Form.Label>
      <Form.Control 
        type="number" 
        name="initialDeposit" 
        placeholder="Amount for initial deposit" 
        value={formData.initialDeposit || ''} 
        onChange={handleInputChange} 
      />
      <Form.Control 
        type="number" 
        name="healthSupplies" 
        placeholder="Amount for health supplies" 
        value={formData.healthSupplies || ''} 
        onChange={handleInputChange} 
      />
      <Form.Text>Total: ${Number(formData.initialDeposit || 0) + Number(formData.healthSupplies || 0)}</Form.Text>
    </Form.Group>

    {/* Recurring/Automatic Payment Authorization */}
    <Form.Group className="mb-2">
      <Form.Label>Recurring/Automatic Payment Authorization (Optional):</Form.Label>
      <Form.Control 
        type="number" 
        name="recurringAmount" 
        placeholder="Amount for recurring payments" 
        value={formData.recurringAmount || ''} 
        onChange={handleInputChange} 
      />
      <Form.Check 
        inline 
        label="Automatically charge this account for future invoices" 
        type="checkbox" 
        name="automaticCharge" 
        checked={formData.automaticCharge || false} 
        onChange={handleCheckboxChange} 
      />
    </Form.Group>

    {/* Past Due Invoices */}
    <Form.Group className="mb-2">
      <Form.Label>Past Due Invoices:</Form.Label>
      <Form.Control 
        type="number" 
        name="pastDueDays" 
        placeholder="Days past due for automatic charge" 
        value={formData.pastDueDays || ''} 
        onChange={handleInputChange} 
      />
    </Form.Group>

    {/* General Authorization */}
    <Form.Group className="mb-2">
      <Form.Label>General Authorization:</Form.Label>
      <p>
        I authorize Hearts United Homecare to charge my credit card as indicated above or any time upon my verbal authorization. 
        I agree that I will pay for services rendered and health supplies provided and indemnify and hold Hearts United Homecare 
        harmless against any liability pursuant to these authorizations. I understand that my signature on this form will serve as an 
        authorized signature on the credit card charge slip.
      </p>
    </Form.Group>

    {/* Signature Canvas for Cardholder */}
    <Form.Group className="mb-3">
      <Form.Label>Signature of Cardholder:</Form.Label>
      <SignatureCanvas
        ref={creditCardSignatureRef}
        penColor="black"
        canvasProps={{ width: 500, height: 200, className: 'border' }}
      />
      <Button variant="secondary" onClick={() => clearSignature(creditCardSignatureRef)} className="mt-2">Clear Signature</Button>
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>Date:</Form.Label>
      <Form.Control 
        type="date" 
        name="creditCardAuthDate" 
        value={formData.creditCardAuthDate || ''} 
        onChange={handleInputChange} 
      />
    </Form.Group>

    <Form.Check
      type="checkbox"
      label="I agree to the Credit Card Authorization terms"
      name="agreeToCreditCardAuth"
      checked={formData.agreeToCreditCardAuth}
      onChange={handleCheckboxChange}
    />
  </div>
</Tab>

<Tab eventKey="assessment" title="Assessment">
  <div className="scrollable-content mb-3">
    <h3>Client Assessment</h3>
    <Form.Group className="mb-2">
      <Form.Label>Client's Name:</Form.Label>
      <Form.Control type="text" name="clientName" value={formData.clientName || ''} onChange={handleInputChange} />
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>Date:</Form.Label>
      <Form.Control type="date" name="assessmentDate" value={formData.assessmentDate || ''} onChange={handleInputChange} />
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>Address:</Form.Label>
      <Form.Control type="text" name="address" value={formData.address || ''} onChange={handleInputChange} />
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>Phone:</Form.Label>
      <Form.Control type="text" name="phone" value={formData.phone || ''} onChange={handleInputChange} />
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>City:</Form.Label>
      <Form.Control type="text" name="city" value={formData.city || ''} onChange={handleInputChange} />
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>State:</Form.Label>
      <Form.Control type="text" name="state" value={formData.state || ''} onChange={handleInputChange} />
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>Zip Code:</Form.Label>
      <Form.Control type="text" name="zipCode" value={formData.zipCode || ''} onChange={handleInputChange} />
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>DOB:</Form.Label>
      <Form.Control type="date" name="dob" value={formData.dob || ''} onChange={handleInputChange} />
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>Primary Language:</Form.Label>
      <Form.Control type="text" name="primaryLanguage" value={formData.primaryLanguage || ''} onChange={handleInputChange} />
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>Emergency Contacts:</Form.Label>
      <Form.Control as="textarea" name="emergencyContacts" value={formData.emergencyContacts || ''} onChange={handleInputChange} />
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>Physician:</Form.Label>
      <Form.Control type="text" name="physician" value={formData.physician || ''} onChange={handleInputChange} />
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>Physician Phone:</Form.Label>
      <Form.Control type="text" name="physicianPhone" value={formData.physicianPhone || ''} onChange={handleInputChange} />
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>Pharmacy:</Form.Label>
      <Form.Control type="text" name="pharmacy" value={formData.pharmacy || ''} onChange={handleInputChange} />
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>Pharmacy Phone:</Form.Label>
      <Form.Control type="text" name="pharmacyPhone" value={formData.pharmacyPhone || ''} onChange={handleInputChange} />
    </Form.Group>

    <h4>Power of Attorney (POA)</h4>
    <Form.Group className="mb-2">
      <Form.Check
        type="checkbox"
        label="Power of Attorney"
        name="poa"
        checked={formData.poa || false}
        onChange={handleCheckboxChange}
      />
      <Form.Label>POA Phone:</Form.Label>
      <Form.Control type="text" name="poaPhone" value={formData.poaPhone || ''} onChange={handleInputChange} />
    </Form.Group>

    <h4>Dementia</h4>
    <Form.Check
      type="checkbox"
      label="Dementia"
      name="dementia"
      checked={formData.dementia || false}
      onChange={handleCheckboxChange}
    />
    <Form.Check
      type="checkbox"
      label="Formal Diagnosis"
      name="formalDiagnosis"
      checked={formData.formalDiagnosis || false}
      onChange={handleCheckboxChange}
    />

<Form.Group className="mb-2">
  <Form.Label>Concerns (select all that apply):</Form.Label>
  <Form.Check type="checkbox" label="Memory Loss" name="memoryLoss" checked={formData.memoryLoss || false} onChange={handleCheckboxChange} />
  <Form.Check type="checkbox" label="Changes in Behavior" name="behaviorChanges" checked={formData.behaviorChanges || false} onChange={handleCheckboxChange} />
  <Form.Check type="checkbox" label="Wandering" name="wandering" checked={formData.wandering || false} onChange={handleCheckboxChange} />
  <Form.Check type="checkbox" label="Home Safety" name="homeSafety" checked={formData.homeSafety || false} onChange={handleCheckboxChange} />
  <Form.Check type="checkbox" label="Managing Finances" name="finances" checked={formData.finances || false} onChange={handleCheckboxChange} />
  <Form.Check type="checkbox" label="Taking Medications" name="medications" checked={formData.medications || false} onChange={handleCheckboxChange} />
  <Form.Check type="checkbox" label="Grooming/Personal Care" name="grooming" checked={formData.grooming || false} onChange={handleCheckboxChange} />

  {/* "Other" checkbox with conditional input */}
  <Form.Check 
    type="checkbox" 
    label="Other" 
    name="otherConcern" 
    checked={formData.otherConcern || false} 
    onChange={handleCheckboxChange} 
  />
  
  {formData.otherConcern && (
    <Form.Control 
      type="text" 
      placeholder="Please specify other concerns" 
      name="otherConcernDetails" 
      value={formData.otherConcernDetails || ''} 
      onChange={handleInputChange} 
      className="mt-2"
    />
  )}

</Form.Group>
    <Form.Group className="mb-2">
      <Form.Label>Advanced Directives:</Form.Label>
      <Form.Check
        type="checkbox"
        label="Yes"
        name="advancedDirectives"
        checked={formData.advancedDirectives || false}
        onChange={handleCheckboxChange}
      />
      <Form.Label>Location:</Form.Label>
      <Form.Control type="text" name="advancedDirectivesLocation" value={formData.advancedDirectivesLocation || ''} onChange={handleInputChange} />
    </Form.Group>

    <h4>DNR Order (Do Not Resuscitate)</h4>
    <Form.Check
      type="checkbox"
      label="Yes"
      name="dnrOrder"
      checked={formData.dnrOrder || false}
      onChange={handleCheckboxChange}
    />
    <Form.Group className="mb-2">
      <Form.Label>DNR Location:</Form.Label>
      <Form.Control type="text" name="dnrLocation" value={formData.dnrLocation || ''} onChange={handleInputChange} />
    </Form.Group>



<h4>Medications</h4>
<Form>
  {/* Medications */}
  <Form.Group className="mb-3">
     <Form.Control 
      as="textarea" 
      placeholder="List all medications" 
      name="medications" 
      value={formData.medications || ''} 
      onChange={handleInputChange} 
    />
  </Form.Group>

  {/* Emergency Procedure */}
  <Form.Group className="mb-3">
    <Form.Label>Emergency Procedure:</Form.Label>
    <p>Always call 9-1-1 first if there is an urgent medical condition.</p>
    <Form.Control 
      type="text" 
      placeholder="Receiver’s name" 
      name="receiverName" 
      value={formData.receiverName || ''} 
      onChange={handleInputChange} 
    />
  </Form.Group>

  {/* Functional Limitations/Difficulties */}
  <h4>Functional Limitations or Difficulties</h4>
  {["Hearing", "Vision", "Speech", "Mobility", "Breathing", "Swallowing", "Eating/Drinking", "Cognitive Abilities"].map((field) => (
    <Form.Group key={field} className="mb-3">
      <Form.Label>{field}</Form.Label>
      <div>
        <Form.Check 
          inline 
          type="radio" 
          label="Yes" 
          name={`${field.toLowerCase()}Status`} 
          value="Yes" 
          checked={formData[`${field.toLowerCase()}Status`] === 'Yes'} 
          onChange={handleInputChange} 
        />
        <Form.Check 
          inline 
          type="radio" 
          label="No" 
          name={`${field.toLowerCase()}Status`} 
          value="No" 
          checked={formData[`${field.toLowerCase()}Status`] === 'No'} 
          onChange={handleInputChange} 
        />
        <Form.Check 
          inline 
          type="radio" 
          label="Sometimes" 
          name={`${field.toLowerCase()}Status`} 
          value="Sometimes" 
          checked={formData[`${field.toLowerCase()}Status`] === 'Sometimes'} 
          onChange={handleInputChange} 
        />
      </div>
      <Form.Control 
        type="text" 
        placeholder="Comments" 
        name={`${field.toLowerCase()}Comments`} 
        value={formData[`${field.toLowerCase()}Comments`] || ''} 
        onChange={handleInputChange} 
        className="mt-2"
      />
    </Form.Group>
  ))}
</Form>

<Form>
  {/* Medical History */}
  <h4>Medical History (select all that apply)</h4>
  {[
    "Chronic pain",
    "Blood Pressure issues",
    "Arthritis",
    "Tremors",
    "Diabetes",
    "Paralysis",
    "Wounds",
    "Allergies",
    "History of falls",
    "Incontinence",
    "Seizures",
    "Cancer",
    "Heart issues",
    "Stroke",
    "Memory loss",
    "Recent Surgery"
  ].map((condition) => (
    <Form.Check 
      key={condition} 
      type="checkbox" 
      label={condition} 
      name={condition.replace(/\s+/g, '').toLowerCase()} 
      checked={formData[condition.replace(/\s+/g, '').toLowerCase()] || false} 
      onChange={handleCheckboxChange} 
    />
  ))}

  {/* Assistive Devices */}
  <Form.Group className="mb-3">
    <Form.Label>Assistive Devices Used for Activities of Daily Living:</Form.Label>
    <Form.Control 
      as="textarea" 
      placeholder="List any assistive devices" 
      name="assistiveDevices" 
      value={formData.assistiveDevices || ''} 
      onChange={handleInputChange} 
    />
  </Form.Group>

  {/* Hobbies, Interests or Activities */}
  <Form.Group className="mb-3">
    <Form.Label>Hobbies, Interests or Activities:</Form.Label>
    <Form.Control 
      as="textarea" 
      placeholder="List hobbies or interests" 
      name="hobbies" 
      value={formData.hobbies || ''} 
      onChange={handleInputChange} 
    />
  </Form.Group>

  {/* Goals & Objectives of Services */}
  <Form.Group className="mb-3">
    <Form.Label>Goals & Objectives of Services:</Form.Label>
    <Form.Control 
      as="textarea" 
      placeholder="Describe goals and objectives for services" 
      name="serviceGoals" 
      value={formData.serviceGoals || ''} 
      onChange={handleInputChange} 
    />
  </Form.Group>

  {/* Types of Services Required */}
  <h4>Types of Service(s) Required (select all that apply)</h4>
  {[
    "Companionship",
    "Medication Monitoring",
    "Meal Preparation",
    "Homemaker Services",
    "Personal Care Services",
    "Overnight Care",
    "Transportation",
    "Other"
  ].map((service) => (
    <Form.Check 
      key={service} 
      type="checkbox" 
      label={service} 
      name={service.replace(/\s+/g, '').toLowerCase()} 
      checked={formData[service.replace(/\s+/g, '').toLowerCase()] || false} 
      onChange={handleCheckboxChange} 
    />
  ))}
  {formData.other && (
    <Form.Control 
      type="text" 
      placeholder="Specify other service(s)" 
      name="otherServices" 
      value={formData.otherServices || ''} 
      onChange={handleInputChange} 
      className="mt-2"
    />
  )}

  {/* Receiver's Name */}
  <Form.Group className="mb-3">
    <Form.Label>Receiver’s Name:</Form.Label>
    <Form.Control 
      type="text" 
      placeholder="Enter receiver's name" 
      name="receiverName" 
      value={formData.receiverName || ''} 
      onChange={handleInputChange} 
    />
  </Form.Group>
</Form>

<Form>
  {/* Expected Times and Frequency of Service Delivery */}
  <h4>Expected Times and Frequency of Service Delivery</h4>
  
  <Form.Group className="mb-3">
    <Form.Label>Times:</Form.Label>
    <Form.Control 
      type="text" 
      placeholder="Enter times for service delivery" 
      name="serviceTimes" 
      value={formData.serviceTimes || ''} 
      onChange={handleInputChange} 
    />
  </Form.Group>
  
  <Form.Group className="mb-3">
    <Form.Label>Frequency:</Form.Label>
    <Form.Control 
      type="text" 
      placeholder="Enter frequency of service delivery" 
      name="serviceFrequency" 
      value={formData.serviceFrequency || ''} 
      onChange={handleInputChange} 
    />
  </Form.Group>
  
  <Form.Group className="mb-3">
    <Form.Label>Duration:</Form.Label>
    <Form.Control 
      type="text" 
      placeholder="Enter duration of each service" 
      name="serviceDuration" 
      value={formData.serviceDuration || ''} 
      onChange={handleInputChange} 
    />
  </Form.Group>
  
  {/* Expected Schedule */}
  <h5>Indicate Expected Schedule Below:</h5>
  {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((day) => (
    <Form.Group className="mb-2" key={day}>
      <Form.Label>{day}:</Form.Label>
      <Form.Control 
        type="text" 
        placeholder={`Enter hours for ${day}`} 
        name={`${day.toLowerCase()}Hours`} 
        value={formData[`${day.toLowerCase()}Hours`] || ''} 
        onChange={handleInputChange} 
      />
    </Form.Group>
  ))}
  
  {/* Discharge Plans */}
  <Form.Group className="mb-3">
    <Form.Label>Discharge Plans:</Form.Label>
    <Form.Control 
      as="textarea" 
      placeholder="Describe discharge plans" 
      name="dischargePlans" 
      value={formData.dischargePlans || ''} 
      onChange={handleInputChange} 
    />
  </Form.Group>
  
  {/* Service Plan Development - People Involved */}
  <h4>Service Plan Development</h4>
  <p>The following person(s) helped develop this Service Plan:</p>
  {[0, 1, 2].map((index) => (
    <div key={index} className="d-flex align-items-center mb-2">
      <Form.Control 
        type="text" 
        placeholder="Name" 
        name={`personName_${index}`} 
        value={formData[`personName_${index}`] || ''} 
        onChange={handleInputChange} 
        className="me-2"
      />
      <Form.Control 
        type="text" 
        placeholder="Relationship" 
        name={`relationship_${index}`} 
        value={formData[`relationship_${index}`] || ''} 
        onChange={handleInputChange} 
        className="me-2"
      />
      <SignatureCanvas
        ref={(ref) => signatureRefs.current[`servicePlanSignatureRef_${index}`] = ref}
        penColor="black"
        canvasProps={{ width: 150, height: 50, className: 'border' }}
      />
      <Button 
        variant="secondary" 
        onClick={() => clearSignature(`servicePlanSignatureRef_${index}`)} 
        className="ms-2"
      >
        Clear
      </Button>
    </div>
  ))}

  {/* Notes/Comments */}
  <Form.Group className="mb-3">
    <Form.Label>Notes/Comments:</Form.Label>
    <Form.Control 
      as="textarea" 
      placeholder="Add any additional notes or comments" 
      name="servicePlanNotes" 
      value={formData.servicePlanNotes || ''} 
      onChange={handleInputChange} 
    />
  </Form.Group>

  {/* Client and Representative Signatures */}
  <h4>Signatures</h4>
  <Form.Group className="mb-3">
    <Form.Label>Client/Client’s Representative Signature:</Form.Label>
    <SignatureCanvas
      ref={(ref) => signatureRefs.current['clientServiceSignatureRef'] = ref}
      penColor="black"
      canvasProps={{ width: 500, height: 200, className: 'border' }}
    />
    <Button variant="secondary" onClick={() => clearSignature('clientServiceSignatureRef')} className="mt-2">Clear Signature</Button>
  </Form.Group>
  
  <Form.Group className="mb-2">
    <Form.Label>Date:</Form.Label>
    <Form.Control 
      type="date" 
      name="clientServiceSignatureDate" 
      value={formData.clientServiceSignatureDate || ''} 
      onChange={handleInputChange} 
    />
  </Form.Group>

  <Form.Group className="mb-3">
    <Form.Label>Hearts United Homecare Representative Signature:</Form.Label>
    <SignatureCanvas
      ref={(ref) => signatureRefs.current['agencyServiceSignatureRef'] = ref}
      penColor="black"
      canvasProps={{ width: 500, height: 200, className: 'border' }}
    />
    <Button variant="secondary" onClick={() => clearSignature('agencyServiceSignatureRef')} className="mt-2">Clear Signature</Button>
  </Form.Group>
  
  <Form.Group className="mb-2">
    <Form.Label>Date:</Form.Label>
    <Form.Control 
      type="date" 
      name="agencyServiceSignatureDate" 
      value={formData.agencyServiceSignatureDate || ''} 
      onChange={handleInputChange} 
    />
  </Form.Group>
</Form>
<Form>
  <h4>Fall Risk Assessment</h4>

  {/* Client and Staff Information */}
  <Form.Group className="mb-3">
    <Form.Label>Client Name:</Form.Label>
    <Form.Control 
      type="text" 
      placeholder="Enter client name" 
      name="clientName" 
      value={formData.clientName || ''} 
      onChange={handleInputChange} 
    />
  </Form.Group>

  <Form.Group className="mb-3">
    <Form.Label>Date:</Form.Label>
    <Form.Control 
      type="date" 
      name="assessmentDate" 
      value={formData.assessmentDate || ''} 
      onChange={handleInputChange} 
    />
  </Form.Group>

  <Form.Group className="mb-3">
    <Form.Label>Staff Name:</Form.Label>
    <Form.Control 
      type="text" 
      placeholder="Enter staff name" 
      name="staffName" 
      value={formData.staffName || ''} 
      onChange={handleInputChange} 
    />
  </Form.Group>

  <p><strong>If 6 or more factors are applicable, the client is considered HIGH RISK. Interventions should be implemented based on the client’s condition.</strong></p>

  {/* Risk Factors and Interventions */}
  <Table bordered className="mb-3">
    <thead>
      <tr>
        <th>Factor</th>
        <th>Check if Applicable</th>
        <th>Intervention</th>
        <th>Check if Intervention Done</th>
        <th>Additional Interventions</th>
      </tr>
    </thead>
    <tbody>
      {[
        { factor: "Fall within last 12 months", intervention: "Evaluate cause" },
        { factor: "Muscle weakness", intervention: "Request assistance" },
        { factor: "Unsteady gait", intervention: "Request assistance" },
        { factor: "Connected to tubing (O2, IV, other)", intervention: "Awareness" },
        { factor: "Use of assistive device", intervention: "Appropriate use advice" },
        { factor: "Balance deficit/dizziness", intervention: "Change position slowly" },
        { factor: "Urinary incontinence/urgency", intervention: "Commode" },
        { factor: "Confusion", intervention: "Review medications" },
        { factor: "Impaired memory/judgment", intervention: "Written instructions" },
        { factor: "Seizure disorder", intervention: "Precautions education" },
        { factor: "More than 4 medications", intervention: "Review medications" },
        { factor: "Use of psychotropic, diuretic drugs", intervention: "Review medications" },
        { factor: "Bathroom", intervention: "Grab bar(s) present" },
        { factor: "Toilet seat low", intervention: "Elevated toilet seat" },
        { factor: "Poor lighting", intervention: "Extra lighting" },
        { factor: "Loose rugs/slippery floor", intervention: "Remove hazard" },
        { factor: "Walkways cluttered", intervention: "Remove hazard" },
        { factor: "Cords in pathway", intervention: "Remove hazard" },
        { factor: "Phone, etc. not within reach", intervention: "Place within reach" },
      ].map((item, index) => (
        <tr key={index}>
          <td>{item.factor}</td>
          <td>
            <Form.Check 
              type="checkbox" 
              name={`factorApplicable_${index}`} 
              checked={formData[`factorApplicable_${index}`] || false} 
              onChange={handleCheckboxChange} 
            />
          </td>
          <td>{item.intervention}</td>
          <td>
            <Form.Check 
              type="checkbox" 
              name={`interventionDone_${index}`} 
              checked={formData[`interventionDone_${index}`] || false} 
              onChange={handleCheckboxChange} 
            />
          </td>
          <td>
            <Form.Control 
              type="text" 
              placeholder="Specify additional interventions" 
              name={`additionalInterventions_${index}`} 
              value={formData[`additionalInterventions_${index}`] || ''} 
              onChange={handleInputChange} 
            />
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
</Form>

  </div>
</Tab>

<Tab eventKey="hipaaPolicy" title="HIPAA Policy">
  <div className="scrollable-content mb-3" style={{ maxHeight: '1200px', overflowY: 'scroll', padding: '15px', border: '1px solid #ddd' }}>
    <h3>HIPAA – Policy, Notice, and Consent Form</h3>

    {/* Policy Section */}
    <h4>Policy</h4>
    <p>
      It is the Policy of Hearts United Homecare in-home services to adhere to all HIPAA (Health Insurance
      Portability and Accountability Act) Regulations and to remain current on any and all changes in the same.
    </p>

    {/* Purpose Section */}
    <h4>Purpose</h4>
    <p>
      Hearts United Homecare in-home services will comply with all state codes and regulations, in addition to
      compliance with the Healthcare Insurance Portability and Accountability Act.
    </p>

    {/* Procedure Section */}
    <h4>Procedure</h4>
    <ul>
      <li>Each employee of Hearts United Homecare in-home services is in-serviced to HIPAA Compliance and
        regulations at Orientation and Annually.
      </li>
      <li>Employees will remain compliant with HIPAA regulations as addressed in the:
        <ul>
          <li>HIPAA agreement</li>
          <li>The Employee Handbook</li>
        </ul>
        All of which define and mandate Confidentiality as designated by HIPAA.
      </li>
      <li>Receiver information will not be released to any source outside of the company without signed
        permission from the receiver; Caregiver information will not be released to any source without
        signed permission from the caregiver.
      </li>
      <li>All personal medical information is held confidential and only discussed on a need-to-know basis.</li>
      <li>Employees of this organization will not discuss any personal health information outside the company
        unless necessary to the care and welfare of the individual involved.
      </li>
      <li>Hearts United Homecare in-home services is committed to remaining current on any additions or
        changes to the HIPAA policy and will implement any such changes as required through annual review
        of regulations.
      </li>
      <li>A review of HIPAA standards will be presented to Caregivers at an Annual In-Service; those
        Caregivers not in attendance will receive the review via mail or email.
      </li>
      <li>All Caregivers are required to complete training annually on HIPAA compliance and documentation
        of training will be maintained in the Caregiver’s personnel file.
      </li>
    </ul>

  

    {/* Notice of Privacy Practices */}
    <h4>Notice of Privacy Practices (HIPAA)</h4>
    <p>
      This information describes how information about you may be used and disclosed and how you can get
      access to this information.
    </p>

    <h5>Uses and Disclosures</h5>
    <p>
      There are a number of situations where we may use or disclose to other
      persons or entities your confidential medical information. Certain uses and disclosures will require you to
      sign an Acknowledgement that you received our Notice of Privacy Practices. Any use or disclosure of
      your protected health information for anything other than treatment, payment of health care operations
      requires you to sign an Authorization. Certain disclosures required by law or under emergency
      circumstances may be made without your Acknowledgement or Authorization. Under any circumstance,
      we will use or disclose only the minimum amount of information necessary from your medical records to
      accomplish the intended purpose of the disclosure.
    </p>

    <h5>Use and Disclose Without Patient Acknowledgement of This Notice</h5>
    <p>
      We will attempt in good faith to obtain your signed Acknowledgement that you received this Notice to use
      and disclose your confidential medical information for the following purposes:
    </p>
    <ul>
      <li><strong>Treatment:</strong> We will use your information to make decisions about the provision, coordination or
        management of your care, including determining the appropriate recommendations for the nutritional
        needs of your body. It may also be necessary to share your information with another health care
        caregiver whom we need to consult with respect to your care. We may also disclose certain information
        to ancillary health personnel under appropriate circumstances, or to a facility or other caregivers should
        you require additional care. These are only examples of uses and disclosures of information for
        treatment purposes that may or may not be necessary in your case.
      </li>
      <li><strong>Operations:</strong> Your records may be used in our business planning and development operations, including
        improvement in our methods of operation, and general administrative functions. We may also use the
        information in our overall compliance planning, review activities, and arranging for legal and auditing
        functions.
      </li>
    </ul>

    <h5>Authorization for Use and Disclosure</h5>
    <p>
      Except as outlined in the above sections, your information will not be used or disclosed to any other person
      or entity without your specific Authorization, which may be revoked by you at any time. In particular,
      except to the extent disclosure has been made to government entities required by law to maintain the
      confidentiality of the information, information will not be further disclosed to any other person or entity
      with respect to information concerning mental health treatment, drug and alcohol abuse, HIV/AIDS, or
      sexually transmitted diseases which may be contained in your medical records. We likewise will not
      disclose your medical record information to an employer for purposes of making employment decisions,
      to a liability insurer or attorney as a result of injuries sustained in an automobile accident, or to
      educational authorities without your written authorization.
    </p>

    <h5>Additional Uses and Disclosures</h5>
    <p>We may contact you from time to time to provide appointment reminders.</p>

    {/* Individual Rights */}
    <h4>Individual Rights</h4>
    <ul>
      <li>You may request we restrict the uses and disclosures of your records information for care, payment
        and operations, or restrictions involving your care or payment related to that care. We are not
        required to agree to the restriction; however, if we agree, we will comply with it, except with respect
        to emergencies, disclosure of the information to you, or if we are otherwise required by law to make
        a full disclosure without restriction.
      </li>
      <li>You have the right to request receipt of confidential communications of your information by an
        alternative means or at an alternative location. If you require such an accommodation, you may be
        charged a fee for the accommodation and will be required to specify the alternative address or
        method of contact and how payment will be handled.
      </li>
      <li>You have the right to inspect, copy and request amendment to your records. Access to your records
        will not include notes contained in them, or information compiled in anticipation of or for use in civil,
        criminal, or administrative action or proceeding or for which your access is otherwise restricted by
        law. We may charge a reasonable fee for providing a copy of your records, or a summary of those
        records, at your request, which includes the cost of copying, postage, or preparation of an
        explanation or summary of the information.
      </li>
      <li>All requests for inspection, copying and/or amending information in your records must be made in
        writing and addressed to Carolyne N, at our address. We will respond to your request in a
        timely fashion.
      </li>
      <li>You have the right to obtain a paper copy of this notice if the notice was initially provided to you
        electronically, and to take one home with you if you wish.
      </li>
    </ul>
    <h4>OUR DUTIES:</h4>
    <p>We have the following duties with respect to the maintenance, use and disclosure of our
    records:</p>
    <ul>
      <li>We are required to maintain the privacy of the information in your records and to provide you with
      this Notice of our legal duties and privacy practices with respect to that information.
      </li>
      <li>We are required to abide by the terms of this Notice currently in effect.
      </li>
      <li>We reserve the right to change the terms of this Notice at any time, making the new provisions
effective for all health information and medical records we have and continue to maintain. All
changes in this Notice will be prominently displayed and available at our office.
      </li>
    </ul>
    <h4>COMPLAINTS</h4>
    <p>You may file a written complaint with us at: <b>Hearts United Homecare </b>in-home services</p>
    <h4>CONTACT PERSON</h4>
    <p>All questions concerning this Notice or requests made pursuant to it should be
    addressed to: <b>Carolyne N</b></p>
    {/* Consent */}
    <Form.Check
      type="checkbox"
      label="I acknowledge receipt of the HIPAA Notice of Privacy Practices"
      name="acknowledgeHipaaNotice"
      checked={formData.acknowledgeHipaaNotice || false}
      onChange={handleCheckboxChange}
    />

    {/* HIPAA Privacy Authorization */}
    <h4>HIPAA Privacy Authorization Form</h4>
    <p>
      The purpose of this consent form is to inform you, the consumer, how your personal health information
      is used and/or disclosed by this caregiver or organization. We want you to be fully aware of what we do
      with your information so that you can provide us with your consent in order for us to treat your health
      care needs, receive payment for services rendered, and allow administrative and other types of service
      operations to happen, which are part of normal business activities of the caregiver or organization.
    </p>
    <h4>Your consent</h4>
    <p>I understand that as part of my service, this organization may maintain health records describing my health history, symptoms, test results, diagnoses, treatment, and plans for future care or treatment. I understand that this information serves as:</p>
    <ul>
        <li>A basis for planning my services.</li>
        <li>A source of information for applying my services and other information to my bill(s).</li>
        <li>A means by which my health plan or health insurance company can verify that services billed were actually provided.</li>
        <li>A tool for routine service operations in this organization, such as ensuring that we have quality processes and programs in place and making sure that the service caregivers and professionals who provide my services are competent to do so.</li>
    </ul>

    <h3>I understand that:</h3>
    <ul>
        <li>I have been provided with a Notice of Information Practices that provides specific examples and descriptions of how my personal information is used and disclosed by Hearts United Homecare in-home services.</li>
        <li>I have the right to review the Notice of Information Practices prior to signing this consent;</li>
        <li>Hearts United Homecare in-home services can change its Notice of Information Practices but will notify me of those changes before they are put into practice and will mail me a copy of the new Notice to the address that I have provided;</li>
        <li>I have the right to request restrictions as to how my information may be used or disclosed to carry out activity plan, payment, or service operations, and that Hearts United Homecare in-home services is not required to agree to those restrictions;</li>
        <li>Any restrictions to which Hearts United Homecare in-home services agree to will be respected.</li>
        <li>I may revoke this consent in writing at any time. Further, I am aware that Hearts United Homecare in-home services can proceed with uses and disclosures that pertain to services, payment, or wellness issues that took place before the consent was revoked.</li>
    </ul>

    <h4>Request for Restrictions</h4>
    <p>To request a restriction on the use and disclosure of your personal information related to your treatment, payment for service, or for the service operations of Hearts United Homecare in-home services, please use the space below:</p>
    {/* Requested Restrictions */}
    <Form.Group className="mb-2">
      <Form.Label>Requested Restrictions (Optional)</Form.Label>
      <Form.Control
        as="textarea"
        name="hipaaRestrictions"
        placeholder="Enter any restrictions (e.g., Restriction 1, Restriction 2)"
        value={formData.hipaaRestrictions || ''}
        onChange={handleInputChange}
      />
    </Form.Group>

    {/* Signature Section */}
    <Form.Group className="mb-3">
      <Form.Label>Signature of Patient or Legal Representative:</Form.Label>
      <SignatureCanvas
        ref={hipaaSignatureRef}
        penColor="black"
        canvasProps={{ width: 500, height: 200, className: 'border' }}
      />
      <Button variant="secondary" onClick={() => clearSignature(hipaaSignatureRef)} className="mt-2">Clear Signature</Button>
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>Date:</Form.Label>
      <Form.Control 
        type="date" 
        name="hipaaSignatureDate" 
        value={formData.hipaaSignatureDate || ''} 
        onChange={handleInputChange} 
      />
    </Form.Group>

    <Form.Check
      type="checkbox"
      label="I agree to the HIPAA Policy and provide my consent"
      name="agreeToHipaa"
      checked={formData.agreeToHipaa}
      onChange={handleCheckboxChange}
    />
  </div>
</Tab>
        {/* Use of Client Vehicle Authorization */}
        <Tab eventKey="vehicleAuthorization" title="Vehicle Use Authorization">
  <div className="scrollable-content mb-3" style={{ maxHeight: '1200px', overflowY: 'scroll', padding: '15px', border: '1px solid #ddd' }}>
    <h3>Use of Client Vehicle Authorization</h3>
    <p>
      It is possible that Hearts United Homecare staff members will use a Client’s vehicle in the normal course
      of the employee’s duties to provide services outlined on the Care Plan. By signing this authorization, I
      authorize employees of Hearts United Homecare to use and operate the Client’s vehicle to provide care
      and services for the Client and only for authorized work purposes.
    </p>
    <h4>Conditions for Vehicle Use</h4>
    <p>By allowing Hearts United Homecare employees to use the Client vehicle, I agree to ensure the vehicle is:</p>
    <ul>
      <li>Properly licensed and registered</li>
      <li>Safe to use and operate</li>
      <li>
        Maintains minimum insurance as required by law, which covers Hearts United Homecare employees
        as authorized drivers
      </li>
    </ul>
    <p>
      I understand that Hearts United Homecare insurance policies do not provide coverage for accidents or
      injuries sustained resulting from the use of a Client’s vehicle, as determined by applicable laws and involved
      insurance companies.
    </p>
    <p>
      I will provide proof of current insurance to Hearts United Homecare prior to the use and operation of the
      Client’s vehicle by an employee. I will notify Hearts United Homecare if there is a change in such
      insurance coverage.
    </p>

    <h4>Client Vehicle Information</h4>
    <Form.Group className="mb-2">
      <Form.Label>Make of Vehicle:</Form.Label>
      <Form.Control 
        type="text" 
        name="vehicleMake" 
        placeholder="Enter make of the vehicle" 
        value={formData.vehicleMake || ''} 
        onChange={handleInputChange} 
      />
    </Form.Group>
    <Form.Group className="mb-2">
      <Form.Label>Model:</Form.Label>
      <Form.Control 
        type="text" 
        name="vehicleModel" 
        placeholder="Enter vehicle model" 
        value={formData.vehicleModel || ''} 
        onChange={handleInputChange} 
      />
    </Form.Group>
    <Form.Group className="mb-2">
      <Form.Label>Color and Description:</Form.Label>
      <Form.Control 
        type="text" 
        name="vehicleColorDescription" 
        placeholder="Enter color and description" 
        value={formData.vehicleColorDescription || ''} 
        onChange={handleInputChange} 
      />
    </Form.Group>
    <Form.Group className="mb-2">
      <Form.Label>License Plate Number:</Form.Label>
      <Form.Control 
        type="text" 
        name="vehicleLicensePlate" 
        placeholder="Enter license plate number" 
        value={formData.vehicleLicensePlate || ''} 
        onChange={handleInputChange} 
      />
    </Form.Group>

    {/* Signature for Client/Authorized Representative */}
    <Form.Group className="mb-3">
      <Form.Label>Client/Authorized Representative Signature:</Form.Label>
      <SignatureCanvas
        ref={vehicleAuthorizationSignatureRef}
        penColor="black"
        canvasProps={{ width: 500, height: 200, className: 'border' }}
      />
      <Button variant="secondary" onClick={() => clearSignature(vehicleAuthorizationSignatureRef)} className="mt-2">Clear Signature</Button>
    </Form.Group>

    <Form.Group className="mb-2">
      <Form.Label>Date:</Form.Label>
      <Form.Control 
        type="date" 
        name="vehicleAuthorizationDate" 
        value={formData.vehicleAuthorizationDate || ''} 
        onChange={handleInputChange} 
      />
    </Form.Group>

    {/* Signature for Agency Representative */}
    <Form.Group className="mb-3">
      <Form.Label>Agency Representative Signature:</Form.Label>
      <SignatureCanvas
        ref={agencyVehicleAuthorizationSignatureRef}
        penColor="black"
        canvasProps={{ width: 500, height: 200, className: 'border' }}
      />
      <Button variant="secondary" onClick={() => clearSignature(agencyVehicleAuthorizationSignatureRef)} className="mt-2">Clear Signature</Button>
    </Form.Group>

    <Form.Check
      type="checkbox"
      label="I agree to the Vehicle Use Authorization"
      name="agreeToVehicleUse"
      checked={formData.agreeToVehicleUse}
      onChange={handleCheckboxChange}
    />
  </div>
</Tab>

{/* Signature Tab */}
<Tab eventKey="signature" title="Signature">
  <div className="mb-3">
    <Form.Group controlId="email">
      <Form.Label>Email Address</Form.Label>
      <Form.Control
        type="email"
        placeholder="Enter email"
        value={formData.email || ''} // Use email from formData
        onChange={(e) => {
          const updatedEmail = e.target.value;
          setEmail(updatedEmail);
          setFormData((prevData) => ({ ...prevData, email: updatedEmail })); // Sync email across tabs
        }}
      />
    </Form.Group>
    <div className="mt-3">
      <SignatureCanvas
        ref={signatureRef}
        penColor="black"
        canvasProps={{ width: 500, height: 200, className: 'border' }}
      />
      <Button variant="secondary" onClick={() => clearSignature(signatureRef)} className="mt-2">
        Clear Signature
      </Button>
    </div>
  </div>
</Tab>

      </Tabs>

      <Button variant="primary" onClick={handleSubmit}>Submit</Button>
    </div>
  );
}

export default ClientAdmissionsForm;