import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';

// Initialize Stripe promise outside the component
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentPage = () => {
  const [clientSecret, setClientSecret] = useState('');
  const [customerInfo, setCustomerInfo] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
  });
  const [amount, setAmount] = useState(50); // Default amount
  const [showWarning, setShowWarning] = useState(false);
  const [scheduleType, setScheduleType] = useState('one-time'); // Default to one-time payment

  const fetchPaymentIntent = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/payment/create-payment-intent`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          amount,
          currency: 'usd',
          customer: customerInfo,
          schedule: scheduleType,
        }),
      });

      const data = await response.json();
      setClientSecret(data.clientSecret);
    } catch (error) {
      console.error('Error fetching payment intent:', error.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleAmountChange = (e) => {
    const value = Number(e.target.value);
    setAmount(value);
    if (value > 1000) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (amount > 1000 && !window.confirm('The amount exceeds $1000. Do you want to proceed?')) {
      return; // Abort the payment process if the user cancels
    }
    fetchPaymentIntent();
  };

  return (
    <div style={{ maxWidth: '600px', margin: '0 auto', padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Complete Your Payment</h2>
      <form onSubmit={handleSubmit} style={{ backgroundColor: '#f9f9f9', padding: '20px', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '5px' }}>Payment Type</label>
          <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
            {['one-time', 'weekly', 'bi-weekly', 'monthly'].map((type) => (
              <button
                key={type}
                type="button"
                onClick={() => setScheduleType(type)}
                style={{
                  padding: '10px',
                  backgroundColor: scheduleType === type ? '#007bff' : '#f1f1f1',
                  color: scheduleType === type ? 'white' : 'black',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  fontWeight: scheduleType === type ? 'bold' : 'normal',
                }}
              >
                {type.charAt(0).toUpperCase() + type.slice(1)}
              </button>
            ))}
          </div>
        </div>
        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '5px' }}>Amount (USD)</label>
          <input
            type="number"
            name="amount"
            value={amount}
            onChange={handleAmountChange}
            min="1"
            style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
            required
          />
          {showWarning && (
            <p style={{ color: 'red', fontWeight: 'bold', marginTop: '5px' }}>
              Amount exceeds $1000. You will be prompted to confirm before proceeding.
            </p>
          )}
        </div>
        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '5px' }}>Name</label>
          <input
            type="text"
            name="name"
            value={customerInfo.name}
            onChange={handleInputChange}
            placeholder="Enter your name"
            style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
            required
          />
        </div>
        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '5px' }}>Email</label>
          <input
            type="email"
            name="email"
            value={customerInfo.email}
            onChange={handleInputChange}
            placeholder="Enter your email"
            style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
            required
          />
        </div>
        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '5px' }}>Phone Number</label>
          <input
            type="tel"
            name="phone"
            value={customerInfo.phone}
            onChange={handleInputChange}
            placeholder="Enter your phone number"
            style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
            required
          />
        </div>
        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '5px' }}>Address</label>
          <textarea
            name="address"
            value={customerInfo.address}
            onChange={handleInputChange}
            placeholder="Enter your address"
            style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc', resize: 'none' }}
            rows="3"
            required
          ></textarea>
        </div>
        <button type="submit" style={{ width: '100%', padding: '10px', backgroundColor: '#007bff', color: 'white', border: 'none', borderRadius: '4px', fontWeight: 'bold', cursor: 'pointer' }}>
          Proceed to Payment
        </button>
      </form>
      {clientSecret && (
        <div style={{ marginTop: '20px' }}>
          {/* Use Elements with a constant stripePromise */}
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm clientSecret={clientSecret} />
          </Elements>
        </div>
      )}
    </div>
  );
};

export default PaymentPage;