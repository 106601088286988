import React from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const CheckoutForm = ({ clientSecret }) => {
    console.log('Received clientSecret:', clientSecret); // Debug log
    const stripe = useStripe();
    const elements = useElements();
    const [isProcessing, setIsProcessing] = React.useState(false);
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      if (!stripe || !elements || !clientSecret) {
        console.error('Stripe.js or Elements not loaded, or missing clientSecret');
        alert('Missing required information for payment.');
        return;
      }
  
      setIsProcessing(true);
  
      const cardElement = elements.getElement(CardElement);
  
      try {
        const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: cardElement,
          },
        });
  
        setIsProcessing(false);
  
        if (error) {
          console.error('Payment failed:', error.message);
          alert('Payment failed. Please try again.');
        } else if (paymentIntent.status === 'succeeded') {
          alert('Payment successful!');
        }
      } catch (err) {
        console.error('Error confirming card payment:', err.message);
        setIsProcessing(false);
      }
    };
  
    return (
      <form onSubmit={handleSubmit}>
        <CardElement options={{ hidePostalCode: true }} />
        <button
          type="submit"
          disabled={!stripe || isProcessing}
          style={{
            marginTop: '10px',
            padding: '10px',
            backgroundColor: '#007bff',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
          }}
        >
          {isProcessing ? 'Processing...' : 'Pay Now'}
        </button>
      </form>
    );
  };
  
  export default CheckoutForm;