import React, { useState, useEffect } from 'react';
import './Dashboard.css'; // Create this CSS file for styling (example styles below)

function Dashboard() {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch clients from the backend
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/clients`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
        const data = await response.json();

        // Map backend fields to match frontend expectations
        const formattedClients = data.map((client) => ({
          id: client.id,
          name: `${client.first_name} ${client.last_name}`, // Combine first and last name
          email: client.email || 'N/A', // Handle missing emails
          phone: client.phone_number || 'N/A', // Handle missing phone numbers
          address: client.address || 'N/A', // Handle missing addresses
        }));

        setClients(formattedClients);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching clients:', error);
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  return (
    <div className="dashboard-container">
      <h1>Dashboard</h1>
      <p>Welcome to the dashboard. This is a protected route accessible only after login.</p>

      {loading ? (
        <p>Loading clients...</p>
      ) : clients.length > 0 ? (
        <table className="clients-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Address</th>
            </tr>
          </thead>
          <tbody>
            {clients.map((client, index) => (
              <tr key={client.id}>
                <td>{index + 1}</td>
                <td>{client.name}</td>
                <td>{client.email}</td>
                <td>{client.phone}</td>
                <td>{client.address}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No clients found.</p>
      )}
    </div>
  );
}

export default Dashboard;